<section *ngIf="client !== null" class="contact-section pb-md-5 pb-sm-3 pt-3">
  <div class="container">
    <span *ngIf="hasTutoriel()" class="d-none d-lg-block">
    <a class="d-flex align-items-center mb-2 text-note tuto_start cursor-pointer">
      <div class="text-note__icon"><i class="icon-eye"></i></div>
      <span class="text-note__message">Revoir le guide de pr�sentation</span></a>
    </span>
    <div
      class="row justify-content-center m-0 py-3 py-md-4 contact-section-inner flex-nowrap flex-md-wrap position-relative">

      <div *ngIf="client.agence !== null"
           [ngClass]="{active: item === 'Agence'}" class="d-flex flex-column justify-content-center text-center col-4 contact-item">
        <a (click)="unselectItem()" class="contact-item__close cursor-pointer"><i class="icon-close"></i></a>
        <div (click)="selectItem('Agence')" class="contact-item__icon"><i class="icon-marker"></i></div>
        <div (click)="selectItem('Agence')" class="contact-item__title">Mon agence</div>
        <div class="contact-item__text"><span>{{ client.agence.libelle }} - {{ client.agence.adresse }}</span><br>
          <a [href]="urls.agences" target="_blank"><span>D�couvrir le r�seau Banque Socredo</span></a>
        </div>
      </div>

      <div [ngClass]="{active: item === 'Conseiller'}"
           class="d-flex flex-column justify-content-center text-center col-4 contact-item">
        <a (click)="unselectItem()" class="contact-item__close cursor-pointer"><i class="icon-close"></i></a>
        <div (click)="selectItem('Conseiller')" class="contact-item__icon"><i class="icon-user"></i></div>
        <div (click)="selectItem('Conseiller')" class="contact-item__title">Mon conseiller</div>
        <div class="contact-item__text"><span>{{ client.chargeClient }}</span></div>
      </div>

      <div [ngClass]="{active: item === 'Nous appeler'}"
           class="d-flex flex-column justify-content-center text-center col-4 contact-item">
        <a (click)="unselectItem()" class="contact-item__close cursor-pointer"><i class="icon-close"></i></a>
        <div (click)="selectItem('Nous appeler')" class="contact-item__icon"><i class="icon-cellphone"></i></div>
        <div (click)="selectItem('Nous appeler')" class="contact-item__title">Nous appeler</div>
        <div class="contact-item__text">
          <span>Centre de relation client�le: <a>40 47 00 00</a></span><br><span>du lundi au jeudi de 7h30 � 15h30 et le vendredi de 7h30 � 14h30</span>
        </div>
      </div>
    </div>
  </div>
</section>
