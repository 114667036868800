import {Component, ContentChild, Input} from '@angular/core';
import {LoadedDirective} from '../../directives/loaded.directive';

/**
 *  Il faut utiliser un template.
 *   Voir: https://angular.io/guide/content-projection#conditional
 */
@Component({
  selector: 'app-erreur-or-load [isLoading] [erreur]',
  templateUrl: './erreur-or-load.component.html',
  styleUrls: ['./erreur-or-load.component.scss'],
})
export class ErreurOrLoadComponent {
  /** Message d'erreur survenue. */
  @Input()
  erreur?: string;
  /** La page est en chargement. */
  @Input()
  isLoading: boolean;
  /** Contenu � afficher une fois charg�. */
  @ContentChild(LoadedDirective) content: LoadedDirective;
}
