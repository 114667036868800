import {Injectable} from '@angular/core';
import {Parametre} from '../../api/model/parametre';
import {NGXLogger} from 'ngx-logger';
import {ParametreService} from '../../api/api/parametre.service';
import {BaseService} from './base.service';
import {ErreursService} from './erreurs.service';
import {Observable, of} from "rxjs";
import {tap} from "rxjs/operators";


/**
 * Interface de ParametresService.
 */
export interface IParametresService {
  /**
   * @returns la liste de tous les param�tres.
   */
  listeParametres(): Promise<Parametre[]>;

  /**
   * @returns le param�tre de l'URL des CGU
   */
  urlCgu(): Promise<string>;

  /**
   * @returns les param�tres SOCSECURE
   */
  urlSocSecure(): Promise<string[]>;


  /**
   * @returns l'url de campagne kyc
   */
  urlCampagneKyc(): Promise<string>;

  /**
   * @returns le nombre de mois de recherche pour les relev�s
   */
  nbMoisRechercheReleves(): Promise<number>
}

/**
 * Service pour r�cup�rer les param�tres
 */
@Injectable({
  providedIn: 'root'
})
export class ParametresService extends BaseService implements IParametresService {

  parametres: Parametre[];

  constructor(protected readonly logger: NGXLogger, private readonly parametreApiService: ParametreService,
              readonly erreursService: ErreursService) {
    super(erreursService, logger);
  }

  /**
   * @returns la liste de tous les param�tres.
   */
  listeParametres(): Promise<Parametre[]> {
    return new Promise<Parametre[]>((resolve, reject) => {
      this.recupereParametres().subscribe((value) => {
        this.logger.debug('Liste des param�tres re�ue');
        resolve(value);
      }, (error1) => {
        this.logger.error('Impossible de r�cup�rer la liste des param�tres', error1);
        reject(this.errorBuilder(error1, 'Impossible de r�cup�rer la liste des param�tres'));
      });
    });
  }

  /**
   * @returns le param�tre de l'URL des CGU
   */
  urlCgu(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.recupereParametres().subscribe((value) => {
        this.logger.debug('Liste des param�tres re�ue');
        const param = value.filter(value1 => value1.cle === 'url.cgu').pop();
        if (param) {
          this.logger.debug('URL des CGU', param);
          resolve(param.valeur);
        } else {
          reject(new Error('Impossible de trouver le param�tre des CGU'));
        }
      }, (error1) => {
        this.logger.error('Impossible de r�cup�rer le param�tres des CGU', error1);
        reject(this.errorBuilder(error1, 'Impossible de r�cup�rer le param�tres des CGU'));
      });
    });
  }

  /**
   * @returns les param�tres des URL SOC Secure
   */
  urlSocSecure(): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      this.recupereParametres().subscribe((value) => {
        this.logger.debug('Liste des param�tres re�ue');
        const param = value.filter(value1 => value1.cle === 'url.socsecure.angular').pop();
        const param2 = value.filter(value1 => value1.cle === 'url.socsecure.android').pop();
        const param3 = value.filter(value1 => value1.cle === 'url.socsecure.ios').pop();
        if (param && param2 && param3 && param.valeur && param2.valeur && param3.valeur) {
          this.logger.debug('URL ANGULAR SOCSECURE', param);
          this.logger.debug('URL ANDROID SOCSECURE', param2);
          this.logger.debug('URL IOS SOCSECURE', param3);
          resolve([param.valeur, param2.valeur, param3.valeur]);
        } else {
          reject(new Error('Impossible de trouver les param�tres socsecure'));
        }
      }, (error1) => {
        this.logger.error('Impossible de r�cup�rer les param�tres socsecure', error1);
        reject(this.errorBuilder(error1, 'Impossible de r�cup�rer les param�tres socsecure'));
      });
    });
  }

  /** r�cup�re les parametres en cache ou depuis le WS si le cache est vide */
  recupereParametres(): Observable<Parametre[]> {
    if (this.parametres) {
      return of(this.parametres);
    } else {
      return this.parametreApiService.parametres().pipe(tap(x => this.parametres = x));
    }
  }

  /**
   * @returns le param�tre de l'URL des cr�dits
   */
  urlCredit(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.recupereParametres().subscribe((value) => {
        this.logger.debug('Liste des param�tres re�ue');
        const param = value.filter(value1 => value1.cle === 'url.credit').pop();
        if (param) {
          this.logger.debug('URL des cr�dits', param);
          resolve(param.valeur);
        } else {
          reject(new Error('Impossible de trouver le param�tre des cr�dits'));
        }
      }, (error1) => {
        this.logger.error('Impossible de r�cup�rer le param�tres des cr�dits', error1);
        reject(this.errorBuilder(error1, 'Impossible de r�cup�rer le param�tres des cr�dits'));
      });
    });
  }

  /**
   * @returns le param�tre de l'URL des cr�dits
   */
  urlCampagneKyc(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.recupereParametres().subscribe((value) => {
        this.logger.debug('Liste des param�tres re�ue');
        const param = value.filter(value1 => value1.cle === 'url.campagne.kyc').pop();
        if (param) {
          this.logger.debug('URL des cr�dits', param);
          resolve(param.valeur);
        } else {
          reject(new Error('Impossible de trouver le param�tre de l\'url de campagne kyc'));
        }
      }, (error1) => {
        this.logger.error('Impossible de r�cup�rer le param�tres de l\'url de campagne kyc', error1);
        reject(this.errorBuilder(error1, 'Impossible de r�cup�rer le param�tres de l\'url de campagne kyc'));
      });
    });
  }

  /**
   * @returns le nombre de mois de recherche pour les relev�s
   */
  nbMoisRechercheReleves(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      this.recupereParametres().subscribe((value) => {
        this.logger.debug('Liste des param�tres re�ue');
        const param = value.filter(value1 => value1.cle === 'releves.nb.mois.recherche').pop();
        if (param) {
          this.logger.debug('Nombre de mois de recherche pour les relev�s', param);
          resolve(Number(param.valeur));
        } else {
          reject(new Error('Impossible de trouver le param�tre du nombre de mois de recherche pour les relev�s'));
        }
      }, (error1) => {
        this.logger.error('Impossible de r�cup�rer le param�tres du nombre de mois de recherche pour les relev�s', error1);
        reject(this.errorBuilder(error1, 'Impossible de r�cup�rer le param�tres du nombre de mois de recherche pour les relev�s'));
      });
    });
  }
}
