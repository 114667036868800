import {Injectable} from '@angular/core';
import {ScriptLoaderService} from 'angular-google-charts';
import {NGXLogger} from 'ngx-logger';
import {CoreServiceModule} from '../../core-service.module';
import {GoogleChartsService} from './google-charts.service';

@Injectable({
  providedIn: CoreServiceModule,
})
export class GoogleSoldeChartsService extends GoogleChartsService {

  constructor(protected loaderService: ScriptLoaderService, protected logger: NGXLogger) {
    super(loaderService, logger);
  }

  public buildSoldeChart(elementId: string, data: any[], devise: string, avecAdd: boolean): void {
    const chartFunc = () => {
      try {
        return new google.visualization.LineChart(document.getElementById(elementId));
      } catch (error) {
        this.logger.warn('Impossible de charge le graphe', error);
        return null;
      }
    };

    const dataViewFunc = (): google.visualization.DataView => {
      const dataTable = new google.visualization.DataTable();
      dataTable.addColumn('datetime', 'Date');
      dataTable.addColumn('number', 'Autorisation de d�couvert');
      dataTable.addColumn('number', '');
      dataTable.addRows(data);
      const formatter_date = new google.visualization.DateFormat({pattern: 'dd/MM/yy'});
      const formatter_number = new google.visualization.NumberFormat({groupingSymbol: ' ', fractionDigits: 0, suffix: ' ' + devise});
      formatter_date.format(dataTable, 0);
      formatter_number.format(dataTable, 1);
      formatter_number.format(dataTable, 2);

      const dataView = new google.visualization.DataView(dataTable);
      const colonnes = [];
      colonnes.push(0);
      if (avecAdd) {
        colonnes.push(1);
      }
      colonnes.push(2);
      colonnes.push({
        calc(value, row) {
          const colorHigh = '#9EBE38';
          const colorDefault = '#fdb441';
          const colorDown = '#ff1a60';

          if (value.getValue(row, 2) >= 0) {
            return colorHigh;
          } else if (value.getValue(row, 2) >= value.getValue(row, 1)) {
            return colorDefault;
          }
          return colorDown;
        },
        type: 'string',
        role: 'style',
        sourceColumn: 2,
      });
      dataView.setColumns(colonnes);
      return dataView;
    };

    const options = {
      // curveType: 'function',
      hAxis: {
        format: 'dd/MM/yy',
        gridlineColor: '#fff',
      },
      vAxis: {
        format: '###,###',
        gridlineColor: '#f1f1f1',
      },
      tooltip: {isHtml: true},
      legend: {position: 'bottom'},
      chartArea: {left: '7%', top: '10%', width: '90%', height: '72%'},
      series: {
        0: {color: '#ec7f7f'},
        1: {color: '#ffffff'},
      },
    };
    if (!avecAdd) {
      // on masque la l�gende => pas d'add
      options.legend = {position: 'none'};
    }

    this.buildChartDataView(elementId, dataViewFunc, chartFunc, options);
  }
}
