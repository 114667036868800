import {Injectable} from '@angular/core';
import {Client} from '../../api/model/client';
import {CoreServiceModule} from '../core-service.module';
import TypeAbonnementEnum = Client.TypeAbonnementEnum;

@Injectable({
  providedIn: CoreServiceModule,
})
export class AccesService {
  matrice: Map<EnumFonctionnalite, TypeAbonnementEnum[]>;
  allValue = [TypeAbonnementEnum.Free, TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Premium, TypeAbonnementEnum.Uru, TypeAbonnementEnum.Light];

  constructor() {
    this.matrice = new Map<EnumFonctionnalite, TypeAbonnementEnum[]>();
    this.matrice.set(EnumFonctionnalite.BENEFICIAIRE, [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Premium, TypeAbonnementEnum.Uru, TypeAbonnementEnum.Light]);
    this.matrice.set(EnumFonctionnalite.BUDGET, [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Premium]);
    this.matrice.set(EnumFonctionnalite.CONSULTATION_COMPTE,
      [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Premium, TypeAbonnementEnum.Uru, TypeAbonnementEnum.Light]);
    this.matrice.set(EnumFonctionnalite.CREDITS, [TypeAbonnementEnum.Free, TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Light]);
    this.matrice.set(EnumFonctionnalite.DELEGATION, [TypeAbonnementEnum.Premium]);
    this.matrice.set(EnumFonctionnalite.DEPOT_FICHIER,
      [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Premium]);
    this.matrice.set(EnumFonctionnalite.DETAIL_COMPTE, [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Premium, TypeAbonnementEnum.Uru]);
    this.matrice.set(EnumFonctionnalite.DETAIL_CREDIT,
      [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Premium]);
    this.matrice.set(EnumFonctionnalite.DETAIL_ASSURANCE,
      [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Premium]);
    this.matrice.set(EnumFonctionnalite.FAQ, this.allValue);
    this.matrice.set(EnumFonctionnalite.FLUX_COMPTE, this.allValue);
    this.matrice.set(EnumFonctionnalite.JAUGE, this.allValue);
    this.matrice.set(EnumFonctionnalite.LISTE_COMPTE, this.allValue);
    this.matrice.set(EnumFonctionnalite.MESSAGERIE, this.allValue);
    this.matrice.set(EnumFonctionnalite.MESSAGES_ALERTES, [TypeAbonnementEnum.Free, TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Premium]);
    this.matrice.set(EnumFonctionnalite.MOUVEMENT_COMPTE, this.allValue);
    this.matrice.set(EnumFonctionnalite.CATEGORIE_COMPTE,
      [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Premium]);
    this.matrice.set(EnumFonctionnalite.MOYEN_PAIEMENT, this.allValue);
    this.matrice.set(EnumFonctionnalite.MOYEN_PAIEMENT_CARTES,
      [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Premium, TypeAbonnementEnum.Uru, TypeAbonnementEnum.Light]);
    this.matrice.set(EnumFonctionnalite.MOYEN_PAIEMENT_CHEQUES,
      [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Premium, TypeAbonnementEnum.Light]);
    this.matrice.set(EnumFonctionnalite.MOYEN_PAIEMENT_CHEQUES_OPPOSITION,
      [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Premium]);
    this.matrice.set(EnumFonctionnalite.MOYEN_PAIEMENT_PAIEMENT_EN_LIGNE,
      [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Free, TypeAbonnementEnum.Uru, TypeAbonnementEnum.Light]);
    this.matrice.set(EnumFonctionnalite.PARAM_ALERTES,
      [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Premium]);
    this.matrice.set(EnumFonctionnalite.PROFIL, this.allValue);
    this.matrice.set(EnumFonctionnalite.RELEVES, this.allValue);
    this.matrice.set(EnumFonctionnalite.PARAM_RELEVES, this.allValue);
    this.matrice.set(EnumFonctionnalite.RIB, this.allValue);
    this.matrice.set(EnumFonctionnalite.SYNTHESE, this.allValue);
    this.matrice.set(EnumFonctionnalite.VALIDATION_VIREMENT, [TypeAbonnementEnum.Premium]);
    this.matrice.set(EnumFonctionnalite.VIREMENT,
      [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Premium, TypeAbonnementEnum.Uru, TypeAbonnementEnum.Light]);
    this.matrice.set(EnumFonctionnalite.PARTAGE_DONNEES,
      [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Premium]);
    this.matrice.set(EnumFonctionnalite.MULTIBENEFICIAIRE,
      [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Premium]);
    this.matrice.set(EnumFonctionnalite.VIREMENT_INTERNATIONAL,
      [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Premium]);
    this.matrice.set(EnumFonctionnalite.VIREMENT_PERMANENT,
      [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Premium]);
    this.matrice.set(EnumFonctionnalite.DOSSIER_CLIENT, this.allValue);
  }

  /**
   * A-t-il acc�s � cet fonctionalit�?
   *
   * @param fonctionalite la fonctionnalit� demand�e
   * @param typeAbonnement le type d'abonnement
   * @return true, s'il a acc�s, false sinon
   */
  public hasAcces(fonctionalite: EnumFonctionnalite, typeAbonnement: TypeAbonnementEnum): boolean {
    const list: TypeAbonnementEnum[] = this.access(fonctionalite);
    if (list === null) {
      return false;
    }
    return list.includes(typeAbonnement);
  }

  /**
   * @param fonctionalite la fonctionnalit� demand�e
   * @return la liste des abo autoris�s pour cette fonctionnalit�
   */
  public access(fonctionalite: EnumFonctionnalite): TypeAbonnementEnum[] {
    return this.matrice.get(fonctionalite);
  }
}

/** Fonctionnalit� disponibles. */
export enum EnumFonctionnalite {
  /** synth�se. */
  SYNTHESE,
  /** Liste compte. */
  LISTE_COMPTE,
  /** constultation des comptes. */
  CONSULTATION_COMPTE,
  /** d�tail des comptes. */
  DETAIL_COMPTE,
  /** d�tail des cr�dit. */
  DETAIL_CREDIT,
  /** d�tail des assurance. */
  DETAIL_ASSURANCE,
  /** mouvements des comptes. */
  MOUVEMENT_COMPTE,
  /** cat�gorisation des comptes. */
  CATEGORIE_COMPTE,
  /** Flux des comptes. */
  FLUX_COMPTE,
  /** virement. */
  VIREMENT,
  /** validation virement. */
  VALIDATION_VIREMENT,
  /** d�pot de fichier. */
  DEPOT_FICHIER,
  /** b�n�ficiaires. */
  BENEFICIAIRE,
  /** moyens de paiement. */
  MOYEN_PAIEMENT,
  /** RIB. */
  RIB,
  /** param�trage des alertes. */
  PARAM_ALERTES,
  /** gestion des messages des alertes. */
  MESSAGES_ALERTES,
  /** Jauge. */
  JAUGE,
  /** relev�s. */
  RELEVES,
  /** budget. */
  BUDGET,
  /** messagerie. */
  MESSAGERIE,
  /** faq. */
  FAQ,
  /** Profil. */
  PROFIL,
  /** D�l�gation. */
  DELEGATION,
  /** partage des donn�es */
  PARTAGE_DONNEES,
  /** multibeneficiaires */
  MULTIBENEFICIAIRE,
  /** virement international */
  VIREMENT_INTERNATIONAL,
  /** le parametrage des releves */
  PARAM_RELEVES,
  /** cheques */
  MOYEN_PAIEMENT_CHEQUES,
  /** virement permanent */
  VIREMENT_PERMANENT,
  /** opposition cheque */
  MOYEN_PAIEMENT_CHEQUES_OPPOSITION,
  /** cr�dits */
  CREDITS,
  /** liste des cartes */
  MOYEN_PAIEMENT_CARTES,
  /** paiement en ligne */
  MOYEN_PAIEMENT_PAIEMENT_EN_LIGNE,
  /** dossier client */
  DOSSIER_CLIENT,
}
