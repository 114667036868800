import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Anr} from '../../api/model/anr';
import {BaseService} from './base.service';
import {ErreursService} from './erreurs.service';
import {CoreServiceModule} from '../core-service.module';
import {HttpResponse} from '@angular/common/http';
import {CheckValidationAnr} from '../../api/model/checkValidationAnr';
import {ANRApiService} from '../../api/api/aNRApi.service';
import {ValidationTelephone} from '../../api/model/validationTelephone';
import {DemandeAnr} from '../../api/model/demandeAnr';
import {ValidationEmail} from '../../api/model/validationEmail';

export interface IAnr {
  /**
   * Effectue une validation Anr et renvoi true si l'anr est la premi�re validation d'une double validation
   * @param anr l'anr a valider
   */
  validationAnr(anr: Anr): Promise<HttpResponse<any>>;
}

@Injectable({
  providedIn: CoreServiceModule,
})
export class AnrsService extends BaseService implements IAnr {

  constructor(private readonly anrService: ANRApiService, protected readonly logger: NGXLogger, readonly erreursService: ErreursService) {
    super(erreursService, logger);
  }

  checkValidationAnr(cleAnr: string): Promise<HttpResponse<CheckValidationAnr>> {
    return new Promise<HttpResponse<CheckValidationAnr>>((resolve, reject) => {
      this.anrService.checkValidationAnr(cleAnr, 'response').subscribe((value) => {
        this.logger.debug('V�rification de la validation ANR :', value.body.valider);
        resolve(value);
      }, (error1) => {
        this.logger.warn('Impossible de v�rifier l\'anr', cleAnr);
        reject(this.errorBuilder(error1, 'Imposssible de v�rifier si l\'op�ration est valid�e.'));
      });
    });
  }

  validationAnr(anr: Anr): Promise<HttpResponse<any>> {
    return new Promise<HttpResponse<any>>((resolve, reject) => {
      this.anrService.validationAnr(anr, 'response').subscribe((value) => {
        this.logger.debug('Anr valid� avec succ�s :', anr);
        resolve(value);
      }, (error1) => {
        this.logger.warn('Impossible de valider l\'anr', anr);
        reject(this.errorBuilder(error1, 'La saisie de votre code est invalide ou le d�lai est d�pass�. Veuillez consulter votre historique de compte avant de renouveler votre op�ration.'));
      });
    });
  }

  validationTelephone(validationTelephone: ValidationTelephone): Promise<DemandeAnr> {
    return new Promise<DemandeAnr>((resolve, reject) => {
      this.anrService.validationTelephone(validationTelephone).subscribe((value) => {
        this.logger.debug('Demande de validation du t�l�phone :', validationTelephone);
        resolve(value);
      }, (error1) => {
        this.logger.warn('Impossible de valider le t�l�phone', validationTelephone);
        reject(this.errorBuilder(error1, 'Impossible de valider le t�l�phone'));
      });
    });
  }

  validationEmail(validationEmail: ValidationEmail): Promise<DemandeAnr> {
    return new Promise<DemandeAnr>((resolve, reject) => {
      this.anrService.validationEmail(validationEmail).subscribe((value) => {
        this.logger.debug('Demande de validation de l\'email :', validationEmail);
        resolve(value);
      }, (error1) => {
        this.logger.warn('Impossible de valider l\'adresse email', validationEmail);
        reject(this.errorBuilder(error1, 'Impossible de valider l\'adresse email'));
      });
    });
  }

}
