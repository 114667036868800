import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {format, subDays} from 'date-fns';
import {NGXLogger} from 'ngx-logger';
import {AgenceService} from '../../api/api/agence.service';
import {CategorieService} from '../../api/api/categorie.service';
import {ChequierService} from '../../api/api/chequier.service';
import {ClientService} from '../../api/api/client.service';
import {CompteService} from '../../api/api/compte.service';
import {MouvementService} from '../../api/api/mouvement.service';
import {Agence} from '../../api/model/agence';
import {BorneMinMax} from '../../api/model/borneMinMax';
import {Categorie} from '../../api/model/categorie';
import {Client} from '../../api/model/client';
import {CommandeChequier} from '../../api/model/commandeChequier';
import {CommandeChequierListe} from '../../api/model/commandeChequierListe';
import {Compte} from '../../api/model/compte';
import {CompteListe} from '../../api/model/compteListe';
import {DelivranceChequier} from '../../api/model/delivranceChequier';
import {DemandeAnr} from '../../api/model/demandeAnr';
import {EncoursCarteCompte} from '../../api/model/encoursCarteCompte';
import {EvolutionSolde} from '../../api/model/evolutionSolde';
import {Flux} from '../../api/model/flux';
import {Mouvement} from '../../api/model/mouvement';
import {OperationsCarteCompte} from '../../api/model/operationsCarteCompte';
import {OppositionCheque} from '../../api/model/oppositionCheque';
import {RechercheMouvement} from '../../api/model/rechercheMouvement';
import {RechercheMouvementExport} from '../../api/model/rechercheMouvementExport';
import {RechercheVirement} from '../../api/model/rechercheVirement';
import {Repartition} from '../../api/model/repartition';
import {SensMouvementEnum} from '../../api/model/sensMouvementEnum';
import {Synthese} from '../../api/model/synthese';
import {SyntheseCompte} from '../../api/model/syntheseCompte';
import {TauxCompte} from '../../api/model/tauxCompte';
import {TypeListeCompteEnum} from '../../api/model/typeListeCompteEnum';
import {VirementListe} from '../../api/model/virementListe';
import {VirementPermanentListe} from '../../api/model/virementPermanentListe';
import {CoreServiceModule} from '../core-service.module';
import {BaseService} from './base.service';
import {ErreursService} from './erreurs.service';
import {UserService} from './user.service';
import FormatEnum = RechercheMouvementExport.FormatEnum;

/**
 * Interface du service des comptes.
 */
export interface IComptesService {
  /**
   * Les mouvements d'une synth�se compte.
   *
   * @param synhteseCompte la synth�se compte
   * @param dateDebut la date de d�but
   * @param dateFin la date de fin
   * @param indice l'indice de d�part
   * @param nbElements le nombre d'�l�ments
   */
  mouvements(synhteseCompte: SyntheseCompte, dateDebut: Date, dateFin: Date, indice: number, nbElements: number): Promise<Mouvement[]>;

  /**
   * Charge la synth�se client (liste des comptes)
   * @param client le client concern�
   */
  synthese(client: Client): Promise<Synthese>;

  /**
   * Le suivi mensuel d'un compte
   * @param numeroCompte le numero du compte
   * @param mois Mois en cours - {@param mois} relatif au moi en cours
   */
  flux(numeroCompte: string, mois: number): Promise<Flux>;

  /**
   * Sauvegarde les pr�f�rences de la jauge d'un compte
   * @param bornesMinMax les bornes de la jauge
   * @param numeroCompte le numero du compte
   */
  updateJauge(bornesMinMax: BorneMinMax, numeroCompte: string): Promise<void>;

  /**
   * Recherche les mouvements d'un compte
   * @param rechercheMouvement recherche les mouvements d'un compte
   * @param numeroCompte le numero du compte de recherche
   */
  rechercheMouvement(rechercheMouvement: RechercheMouvement, numeroCompte: string): Promise<Mouvement[]>;

  /**
   * Permutte le pointage d'un mouvement
   * @param idMouvement le mmouvement � pointer/d�-pointer
   */
  pointage(idMouvement: string): Promise<void>;

  /**
   * Met � jour une cat�gorie d'un mouvement
   * @param codeCategorie le code de la cat�gorie
   * @param idMouvement l'id du mouvement
   */
  majCategorie(codeCategorie: string, idMouvement: string): Promise<void>;

  /**
   * R�cup�re la liste des cat�gories disponibles
   * @param sensMouvementEnum le sens Credit ou debit
   * @param numeroCompte le numero du compte concern�
   */
  listeCategories(sensMouvementEnum: SensMouvementEnum, numeroCompte: string): Promise<Categorie[]>;

  /**
   * Ajoute une nouvelle cat�gorie
   * @param libelleCategorie le libelle de la cat�gorie
   * @param typeCategorie le type de la cat�gorie
   * @param numeroCompte le numero du compte concern�
   */
  ajouterCategorie(libelleCategorie: string, typeCategorie: SensMouvementEnum, numeroCompte: string): Promise<Categorie>;

  /**
   * R�cup�re le d�tail d'un compte
   * @param numeroCompte l'id du compte
   */
  detailCompte(numeroCompte: string): Promise<Compte>;

  /**
   * R�cup�re la r�partition par cat�gorie des mouvements.
   * @param numeroCompte le compte concern�
   * @param dateDebut la date de d�but de la recherche
   * @param dateFin la date de fin de la recherche
   */
  repartitionCompte(numeroCompte: string, dateDebut: Date, dateFin: Date): Promise<Repartition>;

  /**
   * R�cup�re l'historique des taux
   * @param numeroCompte le compte concern�
   */
  historiqueTaux(numeroCompte: string): Promise<TauxCompte[]>;

  /**
   * Appel le WS du soldes quotidien
   * @param compte le compte concern�
   */
  soldeQuotidien(compte: Compte): Promise<EvolutionSolde>;

  /** Appel le WS d'export des mouvements en Pdf */
  mouvementsExportPdf(numeroCompte: string, recherche: RechercheMouvementExport): Promise<void>;

  /** Mouvements � venir
   *
   * @param numeroCompte le compte concern�
   */
  aVenir(numeroCompte: string): Promise<Mouvement[]>;

  /**
   * Remonte les encours cartes
   * @param numeroCompte le compte
   */
  encoursCartes(numeroCompte: string): Promise<EncoursCarteCompte[]>;

  /**
   * Remonte le d�tail des encours carte
   * @param numeroCompe le compte
   * @param date date
   */
  detailEncoursCarte(numeroCompe: string, date: string): Promise<OperationsCarteCompte>;

  /**
   * r�cup�re la liste des virements d'un compte
   * @param numeroCompte le numero de compte
   */listeVirements(numeroCompte: string): Promise<VirementListe[]>;

  /**
   * Liste des virements permanent
   * @param recherche le bean de recherche
   * @param numeroCompte le numero de compte
   */
  listeVirementsPermanent(recherche: RechercheVirement, numeroCompte: string): Promise<VirementPermanentListe[]>;

  /**
   * liste des commandes de ch�quier en cours
   * @param numeroCompte le compte
   */
  listeCommandeChequier(numeroCompte: string): Promise<CommandeChequierListe[]>;

  /**
   * Liste des comptes pour le budget
   */
  listeCompteBudget(): Promise<CompteListe[]>;

  /**
   * Commande des ch�quiers
   * @param commande la commande
   * @param numeroCompte le compte
   */
  commandeChequier(commande: CommandeChequier, numeroCompte: string): Promise<DemandeAnr>;

  /**
   * fait opposition a des ch�ques
   * @param opposition l'opposition
   * @param numeroCompte le numero de compte
   */
  oppositionChequier(opposition: OppositionCheque, numeroCompte: string): Promise<DemandeAnr>;

  /**
   * liste des comptes pouvant avoir un chequier (historique)
   */
  listeCompteCheque(): Promise<CompteListe[]>;

  /**
   * liste des comptes pouvant commander un chequier
   */
  listeCompteCommandeCheque(): Promise<CompteListe[]>;

  /**
   * Liste des informations de d�livrance dt'un ch�quier
   * @param numeroCompte le compte
   */
  delivranceChequier(numeroCompte: string): Promise<DelivranceChequier>;

  /**
   * liste des agences de d�livrance d'un ch�queir
   */
  agencesDelivrance(): Promise<Agence[]>;

  /**
   * Liste des comptes pour virement
   */
  listeCompteVirement(): Promise<CompteListe[]>;

  /** liste des comptes pour les transfert de fichier */
  listeCompteTransfert(): Promise<CompteListe[]>;

  /** liste des virement a valider */
  listeVirementAValider(numeroCompte: string): Promise<VirementListe[]>;

  /** liste des virements permanents � valider */
  listeVirementPermanentAValier(numeroCompte: string): Promise<VirementPermanentListe[]>;

  /** Liste des virements rejet�s   */
  listeVirementAValiderRejetes(numeroCompte: string): Promise<VirementListe[]>;

  /** Liste des virements permanents rejet�s */
  listeVirementPermanentAValierRejetes(numeroCompte: string): Promise<VirementPermanentListe[]>;

  /** liste des syntheses compte pour les releves */
  synthesePourReleves(): Promise<Synthese>;

  /** liste des comptes pour le partage de donn�es */
  listeComptePartageDonnees(): Promise<CompteListe[]>;
}

/**
 * Gestion des comptes.
 */
@Injectable({
  providedIn: CoreServiceModule,
})
export class ComptesService extends BaseService implements IComptesService {

  constructor(private userService: UserService, private compteApiService: CompteService, private clientApiService: ClientService,
              private mouvementService: MouvementService, protected logger: NGXLogger,
              private categorieApiService: CategorieService, erreursService: ErreursService,
              private chequierService: ChequierService, private agenceService: AgenceService) {
    super(erreursService, logger);
  }

  /**
   * Les mouvements d'une synth�se compte.
   *
   * @param synhteseCompte la synth�se compte
   * @param dateDebut la date de d�but
   * @param dateFin la date de fin
   * @param indice l'indice de d�part
   * @param nbElements le nombre d'�l�ments
   */
  mouvements(synhteseCompte: SyntheseCompte, dateDebut: Date, dateFin: Date, indice: number, nbElements: number): Promise<Mouvement[]> {

    return new Promise<Mouvement[]>((resolve, reject) => {
      this.logger.debug('Appel des mouvements avec les params suivants : ',
        this.userService.user.client.id, synhteseCompte.id, format(dateDebut, 'YYYY-MM-DD'),
        format(dateFin, 'YYYY-MM-DD'), indice, nbElements);
      this.compteApiService.mouvements(this.userService.user.client.id, synhteseCompte.id, format(dateDebut, 'YYYY-MM-DD'),
        format(dateFin, 'YYYY-MM-DD'), indice, nbElements, 'body').subscribe({

        next: (response: Mouvement[]) => {
          this.logger.debug('mouvements r�cup�r�s', response);
          resolve(response);
        },

        error: (err: HttpErrorResponse) => {
          this.logger.error('mouvements en �chec', err.status, err.message);
          reject(this.errorBuilder(err, 'Impossible de charger la liste des op�rations'));
        },

      });
    });
  }

  /**
   * Charge la synth�se client (liste des comptes)
   * @param client le client concern�
   */
  synthese(client: Client): Promise<Synthese> {
    return new Promise<Synthese>((resolve, reject) => {
      this.clientApiService.synthese(client.id).subscribe(
        (synth: Synthese) => {
          this.logger.info('Liste comptes', synth);
          resolve(synth);
        },
        (error: HttpErrorResponse) => {
          this.logger.error('Erreur lors de l\'appel du WS synth�se pour le client', client);
          reject(this.errorBuilder(error, 'Impossible de charger la liste des comptes'));
        });
    });
  }

  /**
   * Flux des 30 derniers jours.
   * @param numeroCompte le compte concern�
   */
  flux(numeroCompte: string): Promise<Flux> {
    return new Promise<Flux>((resolve, reject) => {
      this.compteApiService.flux(this.userService.clientActif().id, numeroCompte,
        format(subDays(new Date(), 30), 'YYYY-MM-DD'), format(new Date(), 'YYYY-MM-DD')).subscribe((next: Flux) => {
        this.logger.debug('Flux re�u: ', next);
        resolve(next);
      }, (error: HttpErrorResponse) => {
        this.logger.error('Erreur lors de l\'appel du WS flux pour le tuple {client, compte}',
          this.userService.clientActif()?.id, numeroCompte);
        reject(this.errorBuilder(error, 'Impossible de charger les flux'));
      });
    });
  }

  /**
   * Mise � jour de la jauge d'un compte.
   * @param borneMinMax bornes min et max
   * @param numeroCompte le compte concern�
   */
  updateJauge(borneMinMax: BorneMinMax, numeroCompte: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.compteApiService.updateJauge(borneMinMax, this.userService.clientActif().id, numeroCompte).subscribe(
        (next: void) => {
          this.logger.debug('Bornes sauvegard�es:', borneMinMax);
          resolve(next);
        },
        (error: HttpErrorResponse) => {
          this.logger.error('Erreur lors de l\'appel du WS updateJauge pour le compte: ', numeroCompte);
          reject(this.errorBuilder(error, 'Impossible de mettre � jour les pr�f�rences de la jauge'));
        });
    });
  }

  /**
   * Recherche des op�rations/mouvements
   * @param rechercheMouvement la recherche � effectuer
   * @param numeroCompte le num�ro de compte concern�
   */
  rechercheMouvement(rechercheMouvement: RechercheMouvement, numeroCompte: string): Promise<Mouvement[]> {
    this.logger.debug('Recherche mouvement appel�e avec les params suivants : ', rechercheMouvement, numeroCompte);
    return new Promise<Mouvement[]>(
      (resolve, reject) => {
        this.compteApiService.rechercheMouvements(rechercheMouvement, this.userService.clientActif().id, numeroCompte).subscribe(
          (result) => {
            this.logger.debug('Mouvements recherch�s re�us: ', result);
            resolve(result);
          }
          , (error: HttpErrorResponse) => {
            this.logger.error('Erreur lors de l\'apel du WS rechercheMouvement pour les param�tres', rechercheMouvement, numeroCompte);
            reject(this.errorBuilder(error, 'Erreur lors de la recherche d\'un mouvement'));
          });
      });
  }

  /**
   * Pointe un mvt.
   * @param idMouvement ID du mouvement.
   */
  pointage(idMouvement: string): Promise<void> {
    return new Promise<void>(
      ((resolve, reject) => {
        this.mouvementService.pointage(this.userService.clientActif().id, idMouvement).subscribe(
          () => {
            this.logger.debug('mouvement ' + idMouvement + ' point�');
            resolve();
          },
          (error) => {
            this.logger.error('Impossible de pointer le mouvement: ' + idMouvement + ' du client: ' + this.userService.clientActif()?.id);
            reject(this.errorBuilder(error, 'Erreur lors du pointage d\'un mouvement'));
          },
        );
      }),
    );
  }

  /**
   * Cat�gorise un mouvement
   * @param codeCategorie la cat�gorie o� mettre le mvt
   * @param idMouvement le mouvement concern�
   */
  majCategorie(codeCategorie: string, idMouvement: string): Promise<void> {
    return new Promise<void>(
      ((resolve, reject) => {
        this.mouvementService.updateCategorieMouvement(this.userService.clientActif().id, idMouvement, codeCategorie).subscribe(
          () => {
            this.logger.debug('mouvement ' + idMouvement + ' cat�goris� en ' + idMouvement);
            resolve();
          },
          (error) => {
            this.logger.error('Impossible de cat�goriser le mouvement: ' + idMouvement + ' du client: '
              + this.userService.clientActif()?.id + ' pour la cat�gorie ' + codeCategorie);
            reject(this.errorBuilder(error, 'Erreur lors de la cat�gorisation d\'un mouvement'));
          },
        );
      }),
    );
  }

  /**
   * Liste les cat�gories d'un type (d�bit ou cr�dit)
   * @param sensMouvementEnum le sens de la cat�gorie
   * @param numeroCompte le num�ro de compte.
   */
  listeCategories(sensMouvementEnum: SensMouvementEnum, numeroCompte: string): Promise<Categorie[]> {
    return new Promise<Categorie[]>(
      ((resolve, reject) => {
        this.categorieApiService.listeCategories(this.userService.clientActif().id, numeroCompte, sensMouvementEnum).subscribe(
          (next) => {
            this.logger.debug('Liste de cat�gories retrouv�s :', next);
            resolve(next);
          },
          (error) => {
            this.logger.error('Impossible de r�cup�rer les cat�gories du compte: ' + numeroCompte + ' du client: '
              + this.userService.clientActif()?.id + ' danns le sens ' + sensMouvementEnum);
            reject(this.errorBuilder(error, 'Impossible de r�cup�rer les cat�gories disponibles'));
          },
        );
      }),
    );
  }

  /**
   * Ajoute une cat�gorie
   * @param libelleCategorie libell� de la cat�gorie
   * @param typeCategorie le type de cat�gorie (d�bit ou cr�dit)
   * @param numeroCompte le num�ro de compte concern�
   * @deprecated n'est plus utilis� car plus de cat�gorie personnalis�e
   */
  ajouterCategorie(libelleCategorie: string, typeCategorie: SensMouvementEnum, numeroCompte: string): Promise<Categorie> {
    return new Promise<Categorie>(
      ((resolve, reject) => {
        this.categorieApiService.ajouteCategorie(this.userService.clientActif().id,
          numeroCompte, libelleCategorie, typeCategorie).subscribe(
          (next) => {
            this.logger.debug('Cat�gorie ajout�e :', next);
            resolve(next);
          },
          (error) => {
            this.logger.error('Impossible d\ajouter la cat�gorie ' + libelleCategorie + ' de type ' + typeCategorie +
              ' au compte: ' + numeroCompte + ' du client: ' + this.userService.clientActif()?.id);
            reject(this.errorBuilder(error, 'Impossible d\'ajouter la cat�gorie'));
          },
        );
      }),
    );
  }

  /**
   * Appel le WS du d�tail d'un compte.
   * @param numeroCompte le num�ro de compte concern�
   */
  detailCompte(numeroCompte: string): Promise<Compte> {
    return new Promise<Compte>(
      (resolve, reject) => {
        this.compteApiService.compte(this.userService.clientActif().id, numeroCompte).subscribe((next) => {
          this.logger.debug('Compte r�cup�r� ' + next.id);
          resolve(next);
        }, (error1) => {
          this.logger.error('Impossible de r�cup�rer le compte ' + numeroCompte);
          reject(this.errorBuilder(error1, 'Impossible de r�cup�rer le compte demand�'));
        });
      });
  }

  repartitionCompte(numeroCompte: string, dateDebut: Date, dateFin: Date): Promise<Repartition> {
    return new Promise<Repartition>(((resolve, reject1) => {
      this.compteApiService.repartitionCategorie(this.userService.clientActif().id,
        numeroCompte, format(dateDebut, 'YYYY-MM-DD'), format(dateFin, 'YYYY-MM-DD')).subscribe(
        (next) => {
          this.logger.debug('R�partition r�cup�r�e pour le compte ' + numeroCompte + ' :', next);
          resolve(next);
        },
        (error1) => {
          this.logger.error('Impossible de r�cup�rer la r�partition par cat�gorie pour les param�tres suivants : ',
            this.userService.clientActif()?.id, numeroCompte, dateDebut.toISOString(), dateFin.toISOString());
          reject1(this.errorBuilder(error1, 'Impossible de r�cup�rer la r�partition par cat�gorie'));
        },
      );
    }));
  }

  historiqueTaux(numeroCompte: string): Promise<TauxCompte[]> {
    return new Promise<TauxCompte[]>(
      (resolve, reject) => {
        this.compteApiService.tauxCompte(this.userService.clientActif().id, numeroCompte).subscribe((next) => {
          this.logger.debug('Historique r�cup�r�', next);
          resolve(next);
        }, (error1) => {
          this.logger.error('Impossible de r�cup�rer le l\'historique des taux du compte ' + numeroCompte);
          reject(this.errorBuilder(error1, 'Impossible de r�cup�rer le l\'historique des taux'));
        });
      });

  }

  /**
   * Appel le WS du soldes quotidien
   * @param compte le compte concern�
   */
  soldeQuotidien(compte: Compte): Promise<EvolutionSolde> {
    return new Promise<EvolutionSolde>(
      (resolve, reject) => {
        this.compteApiService.evolutionSolde(this.userService.clientActif().id, compte.id,
          format(subDays(new Date(), compte.nbJoursMaxMouvement), 'YYYY-MM-DD'), format(new Date(), 'YYYY-MM-DD'))
          .subscribe((next) => {
            this.logger.debug('soldes r�cup�r�s', next);
            resolve(next);
          }, (error1) => {
            this.logger.error('Impossible de r�cup�rer les soldes quotidiens du compte ' + compte?.id);
            reject(this.errorBuilder(error1, 'Impossible de r�cup�rer les soldes quotidiens du compte ' + compte.id));
          });
      });
  }

  mouvementsExportPdf(numeroCompte: string, recherche: RechercheMouvement): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.mouvementService.mouvementsExport(this.toRechercheMouvementExport(recherche, 'PDF'), this.userService.clientActif().id, numeroCompte)
        .subscribe((value) => {
          // this.logger.debug('Pdf des mouvements re�u :', value);
          this.userService.dowloadFile(value, 'Mouvement_compte_' + numeroCompte + '.pdf', 'application/pdf');
          resolve();
        }, (error1) => {
          this.logger.error(error1);
          this.logger.error('Impossible de t�l�charcher le pdf pour les valeurs suivantes: ',
            this.userService.clientActif().id, numeroCompte, recherche);
          reject(this.errorBuilder(error1, 'Impossible de t�l�charger le fichier PDF'));
        });
    });
  }

  mouvementsExportCsv(numeroCompte: string, recherche: RechercheMouvement): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.mouvementService.mouvementsExport(this.toRechercheMouvementExport(recherche, 'CSV'), this.userService.clientActif().id, numeroCompte)
        .subscribe((value) => {
          this.userService.dowloadFile(value, 'Mouvement_compte_' + numeroCompte + '.csv', 'text/csv');
          resolve();
        }, (error1) => {
          this.logger.error(error1);
          this.logger.error('Impossible de t�l�charcher le csv pour les valeurs suivantes: ',
            this.userService.clientActif().id, numeroCompte, recherche);
          reject(this.errorBuilder(error1, 'Impossible de t�l�charger le fichier CSV'));
        });
    });
  }

  aVenir(numeroCompte: string): Promise<Mouvement[]> {
    return new Promise<Mouvement[]>((resolve, reject) => {
      this.mouvementService.aVenir(this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('Op�rations � venir r�cup�r�es : ', value);
        resolve(value);
      }, (error1) => {
        this.logger.error('Impossible de r�cup�rer les op�rations � venir du compte ', numeroCompte);
        reject(this.errorBuilder(error1, 'Impossible de r�cup�rer les op�rations � venir.'));
      });
    });
  }

  encoursCartes(numeroCompte: string, toutes?: boolean): Promise<EncoursCarteCompte[]> {
    return new Promise<EncoursCarteCompte[]>((resolve, reject) => {
      this.compteApiService.encoursCarteCompte(this.userService.clientActif().id, numeroCompte, toutes).subscribe((value) => {
        this.logger.debug('Encours cartes r�cup�r�s: ', value);
        resolve(value);

      }, (error1) => {
        this.logger.error('Impossible de r�cup�rer les encours cartes du compte : ', numeroCompte);
        reject(this.errorBuilder(error1, 'Impossible de r�cup�rer les encours cartes.'));
      });
    });
  }

  detailEncoursCarte(numeroCompe: string, date: string): Promise<OperationsCarteCompte> {
    return new Promise<OperationsCarteCompte>((resolve, reject) => {
      this.compteApiService.operationsCarteCompte(this.userService.clientActif().id,
        numeroCompe, date).subscribe((value) => {
          this.logger.debug('Op�rations Cartes r�cup�r�es :', value);
          resolve(value);
        },
        (error1) => {
          this.logger.error('Impossible de r�cup�rer le d�tail des encours cartes du tuple compte,date', numeroCompe, date);
          reject(this.errorBuilder(error1, 'Impossible de r�cup�rer les op�rations cartes'));
        });
    });
  }

  listeVirements(numeroCompte: string): Promise<VirementListe[]> {
    return new Promise<VirementListe[]>((resolve, reject) => {
      this.compteApiService.virements(this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('Virement re�u', value);
        resolve(value);
      }, (error1) => {
        this.logger.error('Impossible de r�cup�rer la liste des virements de ces param�tres', numeroCompte, error1);
        reject(this.errorBuilder(error1, 'Impossible de r�cup�rer la liste des virements'));
      });
    });
  }

  listeVirementsPermanent(recherche: RechercheVirement, numeroCompte: string): Promise<VirementPermanentListe[]> {
    return new Promise<VirementPermanentListe[]>((resolve, reject) => {
      this.compteApiService.virementsPermanent(recherche, this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('Virements re�u', value);
        resolve(value);
      }, (error1) => {
        this.logger.error('Impossible de r�cup�rer la liste des virements permanent de ces param�tres', numeroCompte, recherche, error1);
        reject(this.errorBuilder(error1, 'Impossible de r�cup�rer la liste des virements permanents'));
      });
    });
  }

  listeCommandeChequier(numeroCompte: string): Promise<CommandeChequierListe[]> {
    return new Promise<CommandeChequierListe[]>((resolve, reject) => {
      this.chequierService.listeCommandeChequier(this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('Liste des commandes chequier r�cup�r�e', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de r�cup�rer la liste des commandes de ch�quier', numeroCompte);
        reject(this.errorBuilder(error, 'Impossible de r�cup�rer la liste des commandes de ch�quiers'));
      });
    });
  }

  commandeChequier(commande: CommandeChequier, numeroCompte: string): Promise<DemandeAnr> {

    if (this.userService.user?.isDemo) {
      return this.generePromiseDemandeAnrDemo();
    }
    return new Promise<DemandeAnr>((resolve, reject) => {
      this.chequierService.commandeChequier(commande, this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('Commande de ch�quiers effectu�e', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de commander un ch�quier', commande, numeroCompte);
        reject(this.errorBuilder(error, 'Impossible de commander un ch�quier.'));
      });
    });
  }

  oppositionChequier(opposition: OppositionCheque, numeroCompte: string): Promise<DemandeAnr> {

    if (this.userService.user?.isDemo) {
      return this.generePromiseDemandeAnrDemo();
    }
    return new Promise<DemandeAnr>((resolve, reject) => {
      this.chequierService.oppositionCheque(opposition, this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('Opposition effectu�e', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de faire opposition', opposition, numeroCompte);
        const erreur: Error = this.errorBuilder(error);
        // on traite les 404 avec une popup, pour �viter de suprimer la saisie utilisateur
        if (erreur.name === '404') {
          this.erreursService.traiteErreur(erreur.message);
          erreur.message = undefined;
        }
        reject(erreur);
      });
    });
  }

  listeCompteCheque(): Promise<CompteListe[]> {
    return new Promise<CompteListe[]>((resolve, reject) => {
      this.compteApiService.listeComptes(this.userService.clientActif().id, TypeListeCompteEnum.ListeChequier).subscribe((value) => {
        this.logger.debug('Liste compte ch�quier r�cup�r�e', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de r�cup�rer la liste des comptes ch�que', error);
        reject(this.errorBuilder(error));
      });
    });
  }

  listeCompteCommandeCheque(): Promise<CompteListe[]> {
    return new Promise<CompteListe[]>((resolve, reject) => {
      this.compteApiService.listeComptes(this.userService.clientActif().id, TypeListeCompteEnum.CommandeChequier).subscribe((value) => {
        this.logger.debug('Liste compte commande ch�quier r�cup�r�e', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de r�cup�rer la liste des comptes de commande de ch�quiers', error);
        reject(this.errorBuilder(error));
      });
    });
  }

  listeComptePartageDonnees(): Promise<CompteListe[]> {
    return new Promise<CompteListe[]>((resolve, reject) => {
      this.compteApiService.listeComptes(this.userService.clientActif().id, TypeListeCompteEnum.PartageDonnees).subscribe((value) => {
        this.logger.debug('Liste compte partage donn�es r�cup�r�e', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de r�cup�rer la liste des comptes de partage de donn�es', error);
        reject(this.errorBuilder(error));
      });
    });
  }

  delivranceChequier(numeroCompte: string): Promise<DelivranceChequier> {
    return new Promise<DelivranceChequier>((resolve, reject) => {
      this.chequierService.delivranceChequier(this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('D�livrance ch�quier r�cup�r� ', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible les r�cup�rer les modes de d�livrance', error);
        reject(this.errorBuilder(error));
      });
    });
  }

  agencesDelivrance(): Promise<Agence[]> {
    return new Promise<Agence[]>((resolve, reject) => {
      this.agenceService.agencesSab().subscribe((value) => {
        this.logger.debug('Agences SAB r�cup�r�es', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de r�cup�rer la liste des agences');
        reject(this.errorBuilder(error, 'Impossible de r�cup�rer la liste des agences'));
      });
    });
  }

  listeCompteVirement(): Promise<CompteListe[]> {
    return new Promise((resolve, reject) => {
      this.compteApiService.listeComptes(this.userService.clientActif().id, TypeListeCompteEnum.Virement).subscribe((value) => {
        this.logger.debug('Liste des comptes virement r�cup�r�s', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de r�cup�rer la liste des virements');
        reject(this.errorBuilder(error, 'Impossible de r�cup�rer la liste des comptes'));
      });
    });
  }

  listeCompteTransfert(): Promise<CompteListe[]> {
    return new Promise((resolve, reject) => {
      this.compteApiService.listeComptes(this.userService.clientActif().id, TypeListeCompteEnum.Transfert).subscribe((value) => {
        this.logger.debug('Liste des comptes transfert r�cup�r�s', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de r�cup�rer la liste des comptes de transfert en consultation');
        reject(this.errorBuilder(error, 'Impossible de r�cup�rer la liste des comptes'));
      });
    });
  }

  listeCompteBudget(): Promise<CompteListe[]> {
    return new Promise((resolve, reject) => {
      this.compteApiService.listeComptes(this.userService.clientActif().id, TypeListeCompteEnum.Budget).subscribe((value) => {
        this.logger.debug('Liste des comptes Budget r�cup�r�s', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de r�cup�rer la liste des comptes de Budget');
        reject(this.errorBuilder(error, 'Impossible de r�cup�rer la liste des comptes'));
      });
    });
  }

  listeVirementAValider(numeroCompte: string): Promise<VirementListe[]> {
    return new Promise((resolve, reject) => {
      this.compteApiService.virementsAValider(this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('Liste des virments a valider r�cup�r�', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de r�cup�rer la liste des virements � valider', error);
        reject(this.errorBuilder(error, 'Impossible de r�cup�rer les virements � valider'));
      });
    });
  }

  listeVirementPermanentAValier(numeroCompte: string): Promise<VirementPermanentListe[]> {
    return new Promise((resolve, reject) => {
      this.compteApiService.virementsAValiderPermanent(this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('Liste des virments permanent a valider r�cup�r�', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de r�cup�rer la liste des virements permanent � valider', error);
        reject(this.errorBuilder(error, 'Impossible de r�cup�rer les virements permanents � valider'));
      });
    });
  }

  listeVirementAValiderRejetes(numeroCompte: string): Promise<VirementListe[]> {
    return new Promise((resolve, reject) => {
      this.compteApiService.virementsRejetes(this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('Liste des virments rejet�s r�cup�r�', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de r�cup�rer la liste des virements rejet�s', error);
        reject(this.errorBuilder(error, 'Impossible de r�cup�rer les virements rejet�s'));
      });
    });
  }

  listeVirementPermanentAValierRejetes(numeroCompte: string): Promise<VirementPermanentListe[]> {
    return new Promise((resolve, reject) => {
      this.compteApiService.virementsPermanantsRejetes(this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('Liste des virments permanent rejet�s r�cup�r�', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de r�cup�rer la liste des virements permanent rejet�s', error);
        reject(this.errorBuilder(error, 'Impossible de r�cup�rer les virements permanents rejet�s'));
      });
    });
  }

  synthesePourReleves(): Promise<Synthese> {
    return new Promise<Synthese>((resolve, reject) => {
      this.synthese(this.userService.clientActif()).then((value) => {
        value.listeComptesCourants = value.listeComptesCourants ? value.listeComptesCourants.filter((value1) => value1.categorie !== 'dat') : [];
        value.listeComptesDevises = value.listeComptesDevises ? value.listeComptesDevises.filter((value1) => value1.categorie !== 'dat') : [];
        value.listeComptesEpargne = value.listeComptesEpargne ? value.listeComptesEpargne.filter((value1) => value1.categorie !== 'dat') : [];
        value.listeComptesTiers = value.listeComptesTiers ? value.listeComptesTiers.filter((value1) => value1.categorie !== 'dat') : [];
        resolve(value);
      }).catch((reason) => {
        reject(reason);
      });
    });
  }

  /**
   * Converti une recherche mouvement en recherche mouvement avec un type d'export
   * @param rechercheMouvement la recherche mouvement
   * @param formatEnum le type d'export
   */
  private toRechercheMouvementExport(rechercheMouvement: RechercheMouvement, formatEnum: FormatEnum): RechercheMouvementExport {
    return {
      comparaison: rechercheMouvement.comparaison,
      dateDebut: rechercheMouvement.dateDebut,
      dateFin: rechercheMouvement.dateFin,
      format: formatEnum,
      indiceDebut: rechercheMouvement.indiceDebut,
      libelle: rechercheMouvement.libelle,
      montant: rechercheMouvement.montant,
      nbElements: rechercheMouvement.nbElements,
      sensMouvement: rechercheMouvement.sensMouvement,
    };

  }

}
