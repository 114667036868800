import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-modification-mot-de-passe',
  templateUrl: './modification-mot-de-passe.component.html',
  styleUrls: ['./modification-mot-de-passe.component.css']
})
export class ModificationMotDePasseComponent implements OnInit {

  formGroup: UntypedFormGroup;
  /** en cas d'erreur */
  erreur: string;
  /** le formulaire est en cours de chargement */
  isLoading: boolean;
  /** url vers laquel rediriger apr�s le changement */
  @Output() changementMdpEffectue: EventEmitter<any> = new EventEmitter<any>();
  /** url vers laquel rediriger apr�s le changement */
  @Output() reinitDateEffectue: EventEmitter<any> = new EventEmitter<any>();
  /** reinitialisation en chargement */
  isLoadingReinitDate: boolean;

  constructor(private formBuilder: UntypedFormBuilder, public userService: UserService) {
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      ancienMdp: [null, Validators.required],
      nouveauMdp: [null, [Validators.required, Validators.minLength(10)]],
      confirmation: [null, [this.validatorConfirmationMdp.bind(this), Validators.required]],
    });

    this.formGroup.controls.nouveauMdp.valueChanges.subscribe((value) => {
      this.formGroup.controls.confirmation.updateValueAndValidity();
    });

  }

  validatorConfirmationMdp(control: UntypedFormGroup): null | ValidationErrors {
    if (this.formGroup && this.formGroup.controls.nouveauMdp.value === control.value) {
      return null;
    } else {
      return {confirmation: 'Ce champ doit �tre identique � votre nouveau mot de passe'};
    }
  }

  onSubmit() {
    if (!this.isLoading) {
      this.isLoading = true;

      this.userService.changeMdp(this.formGroup.controls.ancienMdp.value, this.formGroup.controls.nouveauMdp.value).then((demandeAnr) => {
        this.changementMdpEffectue.emit(demandeAnr);
      }).catch((reason) => {
        this.erreur = reason.message;
      }).finally(() => {
        this.isLoading = false;
      });
    }
  }

  continuerSansChanger() {
    if (!this.isLoadingReinitDate) {
      this.isLoadingReinitDate = true;
      this.userService.reinitDateExpirationMotDePasse().then((value) => {
        this.reinitDateEffectue.emit(value);
      }).catch((reason) => {
          this.erreur = reason.message;
        }
      ).finally(() => {
          this.isLoadingReinitDate = false;
        }
      );
    }
  }
}
