import {Component, ElementRef, HostListener, OnInit, SecurityContext, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ParametresService} from '../../services/parametres.service';
import {DomSanitizer} from '@angular/platform-browser';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'app-cgu',
  templateUrl: './cgu.component.html',
  styleUrls: ['./cgu.component.css'],
})
export class CguComponent implements OnInit {
  /** Check box disabled par d�faut. Il faut scroller en bas pour d�bloquer. */
  disabled = true;
  /** En cours de soumission. */
  isSubmit = false;
  /** Fomulaire � soumettre. */
  formGroup: UntypedFormGroup;
  /** case � cocher "accepte". */
  checkAccepte: UntypedFormControl;
  /** l'utilisateur consulte les CGU, pas de validation */
  consultation: boolean;
  /** lien de t�l�chargement du formulaire de r�tractation */
  urlRetractation: string = environment.urlsInstitutionelles.retractation;
  /** URL pour les CGU. */
  urlCgu: string;
  // Get the iframe element reference
  @ViewChild('myFrame', {static: false}) iframe: ElementRef;

  constructor(private readonly userService: UserService, private readonly router: Router, private readonly formBuilder: UntypedFormBuilder,
              private readonly activatedRoute: ActivatedRoute, private readonly http: HttpClient, private readonly logger: NGXLogger,
              private readonly parametreService: ParametresService, private readonly sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.consultation = this.activatedRoute.snapshot.data.consultation;
    this.checkAccepte = this.formBuilder.control(false, [Validators.required]);
    this.checkAccepte.disable();

    this.formGroup = this.formBuilder.group(
      {
        accepte: this.checkAccepte,
      },
    );

    this.parametreService.urlCgu().then((url) => {
      this.urlCgu = this.sanitizer.sanitize(SecurityContext.URL, url);
    }).catch((reason) => {
      this.logger.error('erreur url CGU', reason);
      this.router.navigate(['/error'], {state: {erreur: 'Impossible de charger les CGU'}});
    });
  }

  /**
   * On appele le WS des CGU � la validation
   */
  submitCgu() {
    if (!this.isSubmit && this.checkAccepte.value === true) {
      this.isSubmit = true;
      this.userService.valideCgu()
        .then(() => this.router.navigate(['/home']))
        .finally(() => this.isSubmit = false);
    }
  }

  /**
   * Active la checkbox "accepte" si on est en bas des CGU
   * @param event l'�v�nement g�n�r�
   */
  @HostListener('scroll', ['$event'])
  scrollHandler(event) {
    this.logger.info('coucou');
    // visible height + pixel scrolled >= total height
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.checkAccepte.enable();
    }
  }
}
