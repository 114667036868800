import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {NGXLogger} from 'ngx-logger';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {UtilisateurService} from '../../api/api/utilisateur.service';
import {Client} from '../../api/model/client';
import {DemandeAnr} from '../../api/model/demandeAnr';
import {ModifierMotDePasse} from '../../api/model/modifierMotDePasse';
import {Profil} from '../../api/model/profil';
import {CoreServiceModule} from '../core-service.module';
import {User} from '../models/user';
import {BaseService} from './base.service';
import {ErreursService} from './erreurs.service';
import {ToastService} from './toast.service';
import {ModifierCodeSmsRenforce} from '../../api/model/modifierCodeSmsRenforce';
import {Authentification} from '../../api/model/login/authentification';
import {AuthentificationApiService} from '../../api/api/authentificationApi.service';
import * as uuid from 'uuid';
import {RetourAuthentification} from '../../api/model/login/retourAuthentification';
import {Anr} from '../../api/model/anr';
import {DossierClientApiService} from '../../api/kyc/api/dossierClientApi.service';
import {EtatCompletude} from '../../api/kyc/model/etatCompletude';

/**
 * Interface de UserService.
 */
export interface IUserService {
  /**
   * @return L'utilisateur stocké dans le sessionStorage, sinon null
   */
  readonly user: User;
  /** Le client en observable. */
  readonly client$: Observable<Client>;

  /**
   * Authentifie un utilisateur.
   * @param auth les infos d'authentification
   */
  authentifie(auth: Authentification): Promise<void>;

  /**
   * @return true si l'utilisateur est connecté, false sinon
   */
  isConnected(): boolean;

  /**
   * @return le token ou null si l'utilisateur n'est pas connecté.
   */
  getToken(): string;

  /**
   * return true si le client sélectionné est le client principal (celui de l'abonné qui est le 1er de la liste)
   */
  isClientPrincipal(): boolean;

  /**
   * @return le client principale (le 1er de la liste) qui est celui de l'abonné.
   */
  clientPrincipal(): Client;

  /** Client actif. */
  clientActif(): Client;

  /**
   * Déclenche l'enregistrement d'un fichier
   * @param file le fichier a enregistrer
   * @param fileName le nom du fichier
   * @param mimeType le type du fichier
   */
  dowloadFile(file: Blob, fileName: string, mimeType: string): void;

  /**
   * Déclenche la visu d'un fichier (sauf pour ie/edge)
   * @param file le fichier a enregistrer
   * @param fileName le nom du fichier
   * @param mimeType le type du fichier
   */
  viewFile(file: Blob, fileName: string, mimeType: string): void;

  /** charge les informations de profil de l'utilisateur */
  chargeProfil(): Promise<User>;

  /**
   * Change le mot de passe de l'utilisateur
   * @param ancienMdp l'ancien mot de passe
   * @param nouveauMdp le nouveau mot de passe
   */
  changeMdp(ancienMdp: string, nouveauMdp: string): Promise<DemandeAnr>;

  /**
   * Déconnection de l'aplication
   */
  logOut(): void;

  /**
   * Change l'email de l'utilisateur
   * @param modifierEmail l'email à envoyer
   */
  changeEmail(modifierEmail: string): Promise<HttpResponse<DemandeAnr>>;

  /**
   * Détermine si le client actif est une personne morale
   */
  isClientActifPersonneMorale(): boolean;

  /**
   * C'est la première connexion de l'utilisateur ?
   */
  premiereConnexion(): boolean;

  /** Réinitialise la date d'expiration du mot de passe */
  reinitDateExpirationMotDePasse(): Promise<DemandeAnr>;

  /**
   * Récupère l'empreinte du navigateur
   */
  empreinte(): string;

  /**
   * Stocke l'empreinte du navigateur
   * @param empreinteNavigateur l'empreinte à enregistrer
   */
  stockeEmpreinte(empreinteNavigateur: string): void;

  /**
   * Chargement de l'état de complétude
   */
  chargeEtatCompletude(): Promise<User>;
}

/**
 * Service de gestion de l'utilisateur connecté.
 */
@Injectable({
  providedIn: CoreServiceModule,
})
export class UserService extends BaseService implements IUserService {
  /** Login du user de démonstration  */
  static readonly LOGIN_DEMO = '999999999';
  /** Mdp du user de démonstration  */
  static readonly MDP_DEMO = '999999999';
  /** CSS pour les entreprises (pas le client principal). */
  private static readonly CSS_ENTREPRISE = 'entreprise';
  /** client sélectionné. */
  readonly client$: Observable<Client>;
  /** Client sélectionné */
  private subjectClient: BehaviorSubject<Client>;
  /** Clé pour stocker l'utilisateur dans le sessionStorage */
  private readonly key: string = 'currentUser';
  /** Idle en cours? */
  private idleEnCours = false;
  /** Clé pour stocker l'empreinte. */
  private readonly empreinteKey = 'empreinte';

  /**
   * Constructeur
   * @param authentificationApiService le service d'authentification
   * @param utilisateurApiService le WS d'utilisateur
   * @param dossierClientApiClientService le service api des dossiers client
   * @param logger logger
   * @param router router
   * @param erreursService service des erreurs
   * @param idle idle
   * @param toastService service toast
   * @param zone zone pour le idle
   */
  constructor(private readonly authentificationApiService: AuthentificationApiService,
              private readonly utilisateurApiService: UtilisateurService, private readonly dossierClientApiClientService: DossierClientApiService, protected readonly logger: NGXLogger,
              private readonly router: Router, erreursService: ErreursService, private readonly idle: Idle,
              private readonly toastService: ToastService, private readonly zone: NgZone) {
    super(erreursService, logger);

    this.subjectClient = new BehaviorSubject(null);
    this.client$ = this.subjectClient.asObservable();

    this.configureIdle();

    // on regarde si l'on n'a pas l'utilisateur stocké dans la session
    if (!environment.production) {
      try {
        const store = sessionStorage.getItem(this.key);
        if (store) {
          this.logger.info('donnée récupérée du session store', store);
          this._user = Object.assign(new User(), JSON.parse(store));
          this.logger.debug('utilisateur créé à partir de la session', this.user);
          this.changeClient(this._user.client, false);
          this.idle.watch();
        }
      } catch (e) {
        this.logger.error('impossible d\'accéder au session storage : ', e);
      }
    }
  }

  /** Utilisateur connecté. */
  private _user?: User;

  /**
   * @return L'utilisateur stocké dans le sessionStorage, sinon null
   */
  get user(): User {
    return this._user;
  }

  /**
   * @return le client actif
   */
  clientActif(): Client {
    return this.subjectClient.getValue();
  }

  /**
   * @return le token ou null.
   */
  getToken(): string {
    if (this.user == null) {
      this.logger.debug('pas de token');
      return null;
    }
    this.logger.trace('token trouvé: Bearer ', this.user.token);
    return this.user.token;
  }

  /**
   * @return true si l'utilisateur est connecté, false sinon
   */
  isConnected(): boolean {
    const connected = (this.user != null);
    this.logger.trace('isconnected?', this.user, connected);
    return connected;
  }

  authentifie(auth: Authentification): Promise<void> {
    this.logger.debug('Authentification en cours:', auth.numeroAbonne);
    return new Promise<void>((resolve, reject) => {
      this.authentificationApiService.loginEmpreinte(auth, 'response', false).subscribe({
        next: async (response) => {
          this.logger.info('authentification réussie', response.body, auth.numeroAbonne);
          if (response.status == 201) {
            this.storeUser(response.body, auth.numeroAbonne);
            await this.chargeProfil();
          } else if (response.status == 202) {
            this.storeUser(response.body, auth.numeroAbonne);
            //on traite la demande ANR
            await this.router.navigate(['/auth/validationLogin'], {
              state: {
                demandeANR: response.body.demandeAnr,
                empreinte: auth.empreinteNavigateur
              }
            });
          }
        },
        error: (err: HttpErrorResponse) => {
          reject(this.errorBuilder(err));
        },
      });
    });
  }

  chargeProfil(navigate: boolean = true): Promise<User> {
    this.logger.debug('Chargement du profil');

    return new Promise<User>((resolve, reject) => {
      this.utilisateurApiService.profil('body', false).subscribe({
        next: async (response: Profil) => {
          this.logger.info('profil récupéré avec succés', response);
          this.user.profil = response;
          await this.chargeEtatCompletude();
          this.changeClient(this.user.profil.clients[0], navigate);
          this.renseignePagesTutoriel();
          resolve(this.user);
        },
        error: (err: HttpErrorResponse) => {
          this.logger.error('profil en erreur', err);
          reject(this.errorBuilder(err));
        },
      });
    });
  }

  chargeEtatCompletude(): Promise<User> {
    this.logger.debug('Chargement de l\'état de complétude');

    return new Promise<User>((resolve, reject) => {
      this.dossierClientApiClientService.etatCompletude().subscribe({
        next: (response: EtatCompletude) => {
          this.logger.info('état complétude récupéré avec succès', response);
          this.user.etatCompletude = response;
          resolve(this.user);
        },
        error: (err: HttpErrorResponse) => {
          this.logger.error('état complétude en erreur', err);
          reject(this.errorBuilder(err));
        },
      });
    });
  }

  desactivationSocsecure(): Promise<User> {
    this.logger.debug('Désactivation de SOC Secure');
    return new Promise<User>((resolve, reject) => {
      this.utilisateurApiService.desactivationSocSecure('body', false).subscribe(
        () => {
          this.logger.info('désactivation socsecure réussi');
          this.user.profil.nomPeripherique = null;
          resolve(this.user);
        },
        (error: HttpErrorResponse) => {
          this.logger.error('desactivation socsecure en erreur', error);
          reject(this.errorBuilder(error));
        });
    });
  }

  /** Met toutes les pages tutoriel à true si c'est la première connection */
  renseignePagesTutoriel() {
    if (this.premiereConnexion()) {
      this.user.isPremiereVisiteSynthese = true;
      this.user.isPremiereVisiteDetail = true;
      this.user.isPremiereVisiteListe = true;
    }
  }

  logOut() {
    this.idle.stop();
    this.router.navigate(['/auth/login']).finally(() => {
      if (!environment.production) {
        this.logger.debug('clear le storage');
        try {
          sessionStorage.clear();
        } catch (e) {
          this.logger.error('impossible de suprimer le session storage :', e);
        }
      }
      this._user = null;
      this.subjectClient.next(null);
    });
  }

  /**
   * Change de client.
   * @param client le client sélectionné
   * @param navigate autorise la redirection vers une autre page
   */
  changeClient(client: Client, navigate: boolean = true) {
    this.logger.info('Changement de client', client.nomComplet);
    this.user.client = client;
    this.subjectClient.next(client);
    this.stockeUser(this.user);

    // on peut continuer
    if (navigate) {
      if (client.hasDelegationsSuspendues && client.typeAbonnement === 'premium' && this.user.profil.typeProfil === 'adherent') {
        // si le client morale à des délégations suspendues et que c'est un représentant légal, on le redirige vers la page des délégations
        this.router.navigate(['/delegations']);
      } else {// on renvoie vers la home
        this.router.navigate(['/']);
      }
    }

    this.changeCss();

  }

  /**
   * return true si le client sélectionné est le client principal (celui de l'abonné qui est le 1er de la liste)
   */
  isClientPrincipal(): boolean {
    return this.user && this.user.client && this.user.client.id === this.clientPrincipal().id;
  }

  /**
   * @return le client principale (le 1er de la liste) qui est celui de l'abonné.
   */
  clientPrincipal(): Client {
    if (this.user && this.user.profil && this.user.profil.clients) {
      return this.user.profil.clients[0];
    } else {
      return null;
    }
  }

  changeMdp(ancienMdp: string, nouveauMdp: string): Promise<DemandeAnr> {

    if (this.user.isDemo) {
      return this.generePromiseDemandeAnrDemo();
    }
    const modifierMotDePasse: ModifierMotDePasse = {ancienMotDePasse: ancienMdp, nouveauMotDePasse: nouveauMdp};
    return new Promise<DemandeAnr>((resolve, reject) => {
      this.authentificationApiService.majMotDePasse(modifierMotDePasse).subscribe((value) => {
        this.logger.debug('Mot de passe changé avec succès');
        resolve(value);
      }, (error1) => {
        this.logger.error('Impossible de changer le mdp', error1);
        reject(this.errorBuilder(error1, 'Impossible de changer le mdp'));
      });
    });
  }

  changeCodeSmsRenforce(nouveauCode: string): Promise<DemandeAnr> {

    if (this.user.isDemo) {
      return this.generePromiseDemandeAnrDemo();
    }
    const modifierCodeSmsRenforce: ModifierCodeSmsRenforce = {code: nouveauCode};
    return new Promise<DemandeAnr>((resolve, reject) => {
      this.authentificationApiService.smsRenforce(modifierCodeSmsRenforce).subscribe((value) => {
        this.logger.debug('Code de securité renforcé changé avec succès');
        resolve(value);
      }, (error1) => {
        this.logger.error('Impossible de changer le code de sécurité renforcé', error1);
        reject(this.errorBuilder(error1, 'Impossible de changer le code de sécurité renforcé'));
      });
    });
  }

  reinitDateExpirationMotDePasse(): Promise<DemandeAnr> {

    if (this.user.isDemo) {
      return this.generePromiseDemandeAnrDemo();
    }
    return new Promise<DemandeAnr>((resolve, reject) => {
      this.authentificationApiService.reinitDateExpirationMotDePasse().subscribe((value) => {
        this.logger.debug('Réinitialisation de l\'expiration de mot de passe demandée');
        resolve(value);
      }, (error1) => {
        this.logger.error('Réinitialisation de l\'expiration de mot de passe impossible', error1);
        reject(this.errorBuilder(error1, 'Une erreur est survenue'));
      });
    });
  }

  /**
   * Déclenche l'enregistrement d'un fichier
   * @param file le fichier a enregostrer
   * @param fileName le nom du fichier
   * @param mimeType le type du fichier
   */
  dowloadFile(file: Blob, fileName: string, mimeType?: string) {

    // https://stackoverflow.com/questions/52154874/angular-6-downloading-file-from-rest-api/52687792
    const blob = new Blob([file], {type: mimeType});

    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');

    anchor.download = fileName;
    anchor.href = url;
    // this is necessary as link.click() does not work on the latest firefox
    anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(url);
      anchor.remove();
    }, 500);
  }

  /**
   * visu un fichier (si possible)
   * @param file le fichier a enregostrer
   * @param fileName le nom du fichier
   * @param mimeType le type du fichier
   */
  viewFile(file: Blob, fileName: string, mimeType?: string) {

    // https://stackoverflow.com/questions/52154874/angular-6-downloading-file-from-rest-api/52687792
    const blob = new Blob([file], {type: mimeType});

    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(url);
    }, 500);
  }

  changeEmail(modifierEmail: string): Promise<HttpResponse<DemandeAnr>> {
    return new Promise<HttpResponse<DemandeAnr>>((resolve, reject) => {
      this.authentificationApiService.majEmail({nouvealEmail: modifierEmail}, 'response').subscribe((value) => {
        this.logger.debug('Email changé avec succès');
        resolve(value);
      }, (error1) => {
        this.logger.error('Impossible de changer le mail', error1);
        reject(this.errorBuilder(error1, 'Impossible de changer votre email'));
      });
    });
  }

  /**
   * Valide les CGU de l'utilisateur.
   */
  valideCgu(): Promise<void> {
    this.logger.info('valide CGU');
    return new Promise<void>((resolve, reject) => {
      this.authentificationApiService.valideCgu().subscribe((value) => {
        this.logger.debug('CGU validée avec succès');
        this.user.profil.cguAccepte = true;
        resolve(value);
      }, (error1) => {
        this.logger.error('Impossible de valider les CGU', error1);
        reject(this.errorBuilder(error1, 'Impossible de valider les CGU'));
      });
    });
  }

  /**
   * C'est la première connexion de l'utilisateur ?
   */
  premiereConnexion(): boolean {
    return this.user.profil.derniersAcces && this.user.profil.derniersAcces.length < 2;
  }

  /**
   * Récupère le nom d'un fichier depuis le header Content-disposition de la response Http
   * @param contentDisposition le contenu du header
   */
  public parseFilenameFromContentDisposition(contentDisposition: string) {
    if (!contentDisposition) {
      return null;
    }
    const matches = /filename=(.*)/g.exec(contentDisposition);

    return matches && matches.length > 1 ? matches[1] : null;
  }

  /**
   * Détermine si le client actif est une personne morale
   */
  isClientActifPersonneMorale(): boolean {
    if (this.user.profil.typeProfil === 'delegue') {
      return true;
    }
    return !this.isClientPrincipal();
  }

  /**
   * Récupère l'empreinte du navigateur
   */
  empreinte(): string {
    const empreinteData = localStorage.getItem(this.empreinteKey);
    if (empreinteData) {
      return empreinteData;
    }

    //Si on vaut renforcer la sécurité, il faut faire une empreinte avec des données du navigateur ou de l'OS.
    return uuid.v4();
  }

  /**
   * Valide l'ANR de connexion
   * @param anr ANR à envoyer
   */
  valideConnexion(anr: Anr): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.authentificationApiService.validationLogin(anr).subscribe({
        next: async (response) => {
          this.logger.debug('validation réussie', response);
          this.storeUser(response, this.user.numeroAbonne);
          await this.chargeProfil(false);
          resolve();
        },
        error: (err: HttpErrorResponse) => {
          reject(this.errorBuilder(err, 'Le code saisi est incorrect, veuillez recommencer votre opération.'));
        },
      });
    });
  }

  stockeEmpreinte(empreinteNavigateur: string): void {
    localStorage.setItem(this.empreinteKey, empreinteNavigateur);
  }

  private storeUser(response: RetourAuthentification, numeroAbonne: string): void {
    this._user = new User(response.token, numeroAbonne);
    if (numeroAbonne === UserService.LOGIN_DEMO) {
      this._user.isDemo = true;
    }
    this.stockeUser(this.user);
    this.idle.watch();
  }

  /**
   * Positionne le CSS "entreprise" si nécessaire
   */
  private changeCss() {
    if (this.isClientActifPersonneMorale()) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add(UserService.CSS_ENTREPRISE);
    } else {
      const body = document.getElementsByTagName('body')[0];
      body.classList.remove(UserService.CSS_ENTREPRISE);
    }
  }

  /**
   * Stocke l'utilisateur dans le sessionStorage, user est l'utilisateur à stocker
   *
   * @param user l'utilisateur à stocker
   */
  private stockeUser(user: User) {
    this._user = user;
    if (!environment.production) {
      this.logger.debug('stocke l\'utilisateur dans la session');
      try {
        sessionStorage.setItem(this.key, JSON.stringify(user));
      } catch (e) {
        this.logger.error('impossible d\'accéder au sessionstorage : ', e);
      }
    }
  }

  /** Initialise l'idle. */
  private configureIdle() {
    // le idle pour les deconnexion automatiques
    // au bout de 285 secondes d'inactivité...
    this.idle.setIdle(285);
    // ... laisse 15 secondes à l'utilisateur avant de s'enclencher...
    this.idle.setTimeout(15);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleEnCours = false;
    });

    this.idle.onTimeout.subscribe(() => {
      this.logger.info('Déconnexion automatique');
      this.zone.run(() => this.logOut());
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      if (!this.idleEnCours) {
        // je n'utilise pas la période de grâce. Dans un monde parfait il faudrait afficher une popup à l'utilisateur...
        this.toastService.show('Inactif', 'Inactivité détectée', 'Vous allez être déconnecté dans ' + countdown + ' secondes...');
        this.logger.debug('Vous allez être déconnecté dans ' + countdown + ' secondes...');
      }
      this.idleEnCours = true;
    });
  }
}
