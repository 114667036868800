import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {NgbdSortableHeaderDirective, SortDirection, SortEvent} from '../../directives/sortable.directive';
import {Subject} from 'rxjs';
import {BaseComponent} from '../../classes/base-component';

interface State<S> {
  page: number;
  pageSize: number;
  searchTerm?: S;
  sort: { sortColumn: string; sortDirection: SortDirection };
}

@Component({
  selector: 'app-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.scss'],
})
export class ListeComponent<T, S> extends BaseComponent implements OnInit {
  /** Nombre d'�l�ments propos�s pour la pagination. */
  private static readonly pageSizes = [5, 10, 25, 50];
  @ViewChildren(NgbdSortableHeaderDirective)
  headers: QueryList<NgbdSortableHeaderDirective>;
  /** Liste des donn�es � afficher. */
  listData: T[] = [];
  @Input()
  initSort?: { column: string; direction: SortDirection };
  @Input()
  taillePagination: number = ListeComponent.pageSizes[1];
  /** La recherche. */
  protected readonly _search$ = new Subject<void>();
  /** L'�tat de la pagination. */
  protected _state: State<S>;

  constructor() {
    super();
  }

  /** Nombre total d'element disponible. */
  protected _total = 0;

  get total() {
    return this._total;
  }

  get pageSizes() {
    return ListeComponent.pageSizes;
  }

  get page() {
    return this._state.page;
  }

  set page(page: number) {
    this._set({page});
  }

  get pageSize() {
    return this._state.pageSize;
  }

  set pageSize(pageSize: number) {
    this._set({pageSize});
  }

  get sortColumn() {
    return this._state.sort.sortColumn;
  }

  get sortDirection() {
    return this._state.sort.sortDirection;
  }

  get searchTerm() {
    return this._state.searchTerm;
  }

  set searchTerm(searchTerm: S | undefined) {
    this._state.page = 1;
    this._set({searchTerm});
  }

  set sort(sort: { sortDirection: SortDirection; sortColumn: string }) {
    this._set({sort});
  }

  ngOnInit(): void {
    this._state = {
      page: 1,
      pageSize: this.taillePagination,
      sort: {
        sortColumn: this.initSort?.column ?? '',
        sortDirection: this.initSort?.direction ?? '',
      },
    };
  }

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.sort = {sortColumn: column, sortDirection: direction};
  }

  /**
   * Indique l'ic�ne � afficher pour la colonne triable
   *
   * @param columnName le nom de la colonne
   * @returns la classe de l'image � afficher
   */
  classColonneTri(columnName: string): string {
    if (this.sortColumn === columnName) {
      if (this.sortDirection === 'asc') {
        return 'fa fa-sort-up';
      }
      if (this.sortDirection === 'desc') {
        return 'fa fa-sort-down';
      }
    }

    return 'fa fa-sort text-muted';
  }

  private _set(patch: Partial<State<S>>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }
}
