import {Component} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-version',
  standalone: true,
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent {

  /**
   * Le numero de version
   *
   * @returns le numero de version
   */
  version(): string {
    let versions = environment.version.split('-');
    if (versions.length === 2) {
      return `${versions[0]} (dev ${versions[1]})`;
    }

    versions = environment.version.split('.');
    if (versions.length === 3) {
      if (versions[2] === '0') {
        return `${versions[0]}.${versions[1]}`;
      } else {
        return `${versions[0]}.${versions[1]} (alpha ${versions[2]})`;
      }
    }

    return environment.version;
  }

}
