import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AnrsService} from '../../services/anrs.service';
import {BaseComponent} from '../../classes/base-component';
import {HttpResponse} from '@angular/common/http';
import {DemandeAnr} from '../../../api/model/demandeAnr';

@Component({
  selector: 'app-anr',
  templateUrl: './anr.component.html',
  styleUrls: ['./anr.component.css'],

})
export class AnrComponent extends BaseComponent implements OnInit {

  /** le titre de la page d'anr */
  @Input() titre: string;
  /** le lien vers la page de confirmation */
  @Output() confirmationEvent: EventEmitter<HttpResponse<DemandeAnr>> = new EventEmitter<HttpResponse<DemandeAnr>>();
  /** fermeture en si on est dans une modal */
  @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();
  /** lien du bouton d'annulation */
  @Input() lienAnnulation;
  /** Bandeau affich� */
  @Input() bandeauAffiche = true;
  /** Bandeau affich� */
  @Input() popup = false;

  /** le form group concern� */
  formGroup: UntypedFormGroup;
  /** l'anr � envoyer */
  @Input() demandeAnr: DemandeAnr;

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly anrsService: AnrsService, private readonly router: Router) {
    super();
  }

  ngOnInit() {

    this.formGroup = this.formBuilder.group({
      anr: ['', [Validators.required, Validators.pattern('\\d{6}')]],
    });

  }

  onSubmit() {
    if (!this.isSubmit) {
      this.isSubmit = true;
      this.anrsService.validationAnr({cleAnr: this.demandeAnr.cleAnr, codeAnr: this.formGroup.controls.anr.value})
        .then((value) => {
          this.confirmationEvent.emit(value);
        })
        .catch((reason) => this.erreur = reason.message)
        .finally(() => {
          this.isSubmit = false;
        });
    }
  }

  retourLink() {
    if (this.popup) {
      this.closeEvent.emit();
    } else {
      this.router.navigate([this.lienAnnulation]);
    }
  }

  toggleInfosAnr() {
    $('#infos-anr').toggle();
  }

}
