/**
 * Hoba/wesoc API 2.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';

import { Categorie } from '../model/categorie';
import { SensMouvementEnum } from '../model/sensMouvementEnum';
import {Configuration} from '../conf/configuration';
import {BASE_PATH} from '../conf/variables';
import {Observable} from 'rxjs';
import {CustomHttpUrlEncodingCodec} from '../conf/encoder';


@Injectable()
export class CategorieService {

    protected basePath = 'https://www.socms.pf/hoba/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     *
     * Ajoute une cat�gorie � un compte
     * @param numeroClient num�ro du client
     * @param numeroCompte num�ro du compte
     * @param libelleCategorie libell� de la cat�gorie � ajouter
     * @param typeCategorie type de cat�gorie (d�bit ou cr�dit)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ajouteCategorie(numeroClient: string, numeroCompte: string, libelleCategorie: string, typeCategorie: SensMouvementEnum, observe?: 'body', reportProgress?: boolean): Observable<Categorie>;
    public ajouteCategorie(numeroClient: string, numeroCompte: string, libelleCategorie: string, typeCategorie: SensMouvementEnum, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Categorie>>;
    public ajouteCategorie(numeroClient: string, numeroCompte: string, libelleCategorie: string, typeCategorie: SensMouvementEnum, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Categorie>>;
    public ajouteCategorie(numeroClient: string, numeroCompte: string, libelleCategorie: string, typeCategorie: SensMouvementEnum, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (numeroClient === null || numeroClient === undefined) {
            throw new Error('Required parameter numeroClient was null or undefined when calling ajouteCategorie.');
        }

        if (numeroCompte === null || numeroCompte === undefined) {
            throw new Error('Required parameter numeroCompte was null or undefined when calling ajouteCategorie.');
        }

        if (libelleCategorie === null || libelleCategorie === undefined) {
            throw new Error('Required parameter libelleCategorie was null or undefined when calling ajouteCategorie.');
        }

        if (typeCategorie === null || typeCategorie === undefined) {
            throw new Error('Required parameter typeCategorie was null or undefined when calling ajouteCategorie.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (libelleCategorie !== undefined && libelleCategorie !== null) {
            queryParameters = queryParameters.set('libelleCategorie', <any>libelleCategorie);
        }
        if (typeCategorie !== undefined && typeCategorie !== null) {
            queryParameters = queryParameters.set('typeCategorie', <any>typeCategorie);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Categorie>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/categories`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * R�cup�re la liste des cat�gories pour un compte
     * @param numeroClient num�ro du client
     * @param numeroCompte num�ro du compte
     * @param typeCategorie type de cat�gorie (d�bit ou cr�dit)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listeCategories(numeroClient: string, numeroCompte: string, typeCategorie: SensMouvementEnum, observe?: 'body', reportProgress?: boolean): Observable<Array<Categorie>>;
    public listeCategories(numeroClient: string, numeroCompte: string, typeCategorie: SensMouvementEnum, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Categorie>>>;
    public listeCategories(numeroClient: string, numeroCompte: string, typeCategorie: SensMouvementEnum, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Categorie>>>;
    public listeCategories(numeroClient: string, numeroCompte: string, typeCategorie: SensMouvementEnum, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (numeroClient === null || numeroClient === undefined) {
            throw new Error('Required parameter numeroClient was null or undefined when calling listeCategories.');
        }

        if (numeroCompte === null || numeroCompte === undefined) {
            throw new Error('Required parameter numeroCompte was null or undefined when calling listeCategories.');
        }

        if (typeCategorie === null || typeCategorie === undefined) {
            throw new Error('Required parameter typeCategorie was null or undefined when calling listeCategories.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (typeCategorie !== undefined && typeCategorie !== null) {
            queryParameters = queryParameters.set('typeCategorie', <any>typeCategorie);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Categorie>>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/categories`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Supprimer une cat�gorie d&#x27;un compte
     * @param numeroClient num�ro du client
     * @param numeroCompte num�ro du compte
     * @param codeCategorie code de la cat�gorie � supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public supprimeCategorie(numeroClient: string, numeroCompte: string, codeCategorie: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public supprimeCategorie(numeroClient: string, numeroCompte: string, codeCategorie: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public supprimeCategorie(numeroClient: string, numeroCompte: string, codeCategorie: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public supprimeCategorie(numeroClient: string, numeroCompte: string, codeCategorie: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (numeroClient === null || numeroClient === undefined) {
            throw new Error('Required parameter numeroClient was null or undefined when calling supprimeCategorie.');
        }

        if (numeroCompte === null || numeroCompte === undefined) {
            throw new Error('Required parameter numeroCompte was null or undefined when calling supprimeCategorie.');
        }

        if (codeCategorie === null || codeCategorie === undefined) {
            throw new Error('Required parameter codeCategorie was null or undefined when calling supprimeCategorie.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (codeCategorie !== undefined && codeCategorie !== null) {
            queryParameters = queryParameters.set('codeCategorie', <any>codeCategorie);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/categories`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
