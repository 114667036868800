/**
 * Intercepteur HTTP qui ajoute l'authorisation si l'utilisateur est connect�.
 * https://medium.com/@ryanchenkie_40935/angular-authentication-using-the-http-client-and-http-interceptors-2f9d1540eb8
 */
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse,} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {UserService} from '../services/user.service';
import {ToastService} from '../services/toast.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

  constructor(private userService: UserService, private logger: NGXLogger, private readonly toastService: ToastService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // si on veut on peut filtrer par les URLs (il faudrait le faire pour le login, normalement).
    // https://stackoverflow.com/questions/49746548/how-to-exclude-some-services-like-login-register-from-interceptor-angular-5-ht

    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // rien � faire
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          this.logger.debug('401');
          this.userService.logOut();
          return new Observable();
        } else if (err.status === 503) {
          this.logger.debug('503');
          this.toastService.show('503', 'Service indisponible', err.error);
          new Promise(f => setTimeout(f, 5000)).then(() => this.userService.logOut());
          return new Observable();
        }
        // TODO faut-il renvoyer sur une page d'erreur dans les autres cas ?
      }
    }));
  }
}
