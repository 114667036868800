import {Component, OnInit} from '@angular/core';
import {Client} from '../../../../api/model/client';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css'],
})
export class ProfilComponent implements OnInit {
  clients: Client[];

  constructor(private userService: UserService) { }

  ngOnInit() {
      this.clients = this.userService.user.profil.clients;
  }

  /**
   * Change de client s�lectionn�
   * @param client le client s�lectionn�
   */
  changeClient(client: Client) {
    this.userService.changeClient(client);
  }
}
