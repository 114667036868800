<header class="main-header">
  <div class="header-content header-content--customer">
    <div class="container">
      <div class="row pt-4 pb-4 pb-md-5">
        <div class="col-12 d-flex flex-wrap flex-md-nowrap">
          <a class="main-nav-btn"><i class="fas fa-bars"></i></a>
          <h1 class="logo">
            <a [routerLink]="['/']" class="logo__link"><img alt="#" class="logo__link__img"
                                                            src="assets/images/logo.png"></a>
            <a [routerLink]="['/']" class="logo__link logo__link--mobile"><img alt="#"
                                                                               class="logo__link__img"
                                                                               src="assets/images/logo-mobile.png"></a>
            <span class="logo__text">SOCREDO</span></h1>

          <app-display-user
            class="user-info d-flex align-items-center flex-column align-items-md-end justify-content-center"></app-display-user>

          <div class="user-block d-flex justify-content-end flex-grow-1">
            @if (userService.user.etatCompletude.nbJoursRestant !== 0) {
              <app-parametres
                class="user-item user-item--icon-only d-flex position-relative flex-column align-items-center justify-content-center"></app-parametres>

              <app-profil
                class="user-item user-item--icon-only d-flex position-relative flex-column align-items-center justify-content-center"></app-profil>
            }
            
            <div *ngIf="userService.user?.isComplet()"
                 class="user-item user-item--icon-only d-flex position-relative flex-column align-items-center justify-content-center">
              <a [routerLink]="['/messagerie']"
                 class="user-item__icon d-flex align-items-center position-relative cursor-pointer"><i
                class="icon-mail user-item__icon__image"></i>

                <div *ngIf="nbMessageNonLu && nbMessageNonLu.nbMessageNonLu != 0"
                     class="user-item__icon__number">{{ nbMessageNonLu.nbMessageNonLu }}
                </div>
              </a>
            </div>

            <app-alertes *ngIf="!accesUserService.hasAbonnementUru()  && !this.accesUserService.hasAbonnementLight()"
                         class="user-item user-item--icon-only d-flex position-relative flex-column align-items-center justify-content-center"></app-alertes>


            <div
              class="user-item user-item--icon-only d-flex position-relative flex-column align-items-center justify-content-center">
              <a (click)="logout()" class="user-item__icon cursor-pointer"><i
                class="icon-switch user-item__icon__image"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-navigation></app-navigation>

</header>
