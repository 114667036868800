import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Subject} from 'rxjs';
import {MessageService} from '../../api/api/message.service';
import {ListeMessage} from '../../api/model/listeMessage';
import {Message} from '../../api/model/message';
import {PieceJointe} from '../../api/model/pieceJointe';
import {BaseService} from './base.service';
import {ErreursService} from './erreurs.service';
import {UserService} from './user.service';
import {CoreServiceModule} from '../core-service.module';
import {InfosMessageAlerte} from '../../api/model/infosMessageAlerte';
import {MessageClient} from '../../api/model/messageClient';
import ObjetEnum = MessageClient.ObjetEnum;

export interface IMessagerieService {

  listeMessage$: Subject<ListeMessage>;
  /** l'info du nombre de message non lu */
  nombreMessageNonLu$: Subject<InfosMessageAlerte>;

  /** r�cup�re la liste des messages */
  reloadListeMessage(): Promise<void>;

  /** recup�re le message entier */
  detailMessage(idMessage: number): Promise<Message>;

  /** r�cup�re la pi�ce jointe d'un message */
  pieceJointe(idMessage: number, pieceJointe: PieceJointe): Promise<void>;

  /** envoi un message */
  sendMessage(formData: FormData): Promise<void>;

  /** suprime un message */
  deleteMessage(idMessage: number): Promise<void>;

  /** actualise le nombre de messages non lus */
  actualiseMessagesNonLus(): void;

}

@Injectable({
  providedIn: CoreServiceModule,
})
export class MessagerieService extends BaseService implements IMessagerieService {

  defaultMessage: string;
  defaultObjet: ObjetEnum;
  listeMessage$: Subject<ListeMessage> = new Subject();
  /** l'info du nombre de message non lu */
  nombreMessageNonLu$: Subject<InfosMessageAlerte> = new Subject<InfosMessageAlerte>();

  constructor(private messageService: MessageService, protected logger: NGXLogger,
              private userService: UserService, erreursService: ErreursService) {
    super(erreursService, logger);
  }

  detailMessage(idMessage: number): Promise<Message> {
    return new Promise<Message>((resolve, reject) => {
      this.messageService.message(idMessage).subscribe((value) => {
        this.logger.debug('D�tail Message correctement r�cup�r�', value);
        this.actualiseMessagesNonLus();
        resolve(value);
      }, (error1) => {
        this.logger.error('Impossible de r�cup�rer l\' d�tail du message: ', idMessage, error1);
        reject(this.errorBuilder(error1, 'Impossible de r�cup�rer le message.'));
      });
    });
  }

  pieceJointe(idMessage: number, pieceJointe: PieceJointe): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.messageService.pieceJointeMessage(idMessage, pieceJointe.id).subscribe((value) => {
        this.logger.debug('Pi�ce Jointe ', pieceJointe, ' correctement r�cup�r�e pour le message :', idMessage);
        this.userService.dowloadFile(value, pieceJointe.nomFichier, '');
        resolve();
      }, (error1) => {
        this.logger.error('Impossible de charger la pi�ce jointe :', idMessage, pieceJointe, error1);
        reject(this.errorBuilder(error1, 'Impossible de charger la pi�ce jointe.'));
      });
    });
  }

  sendMessage(formData: FormData): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.messageService.envoiMessage(formData).subscribe(() => {
        this.logger.debug('Envoi du message r�ussi');
        resolve();
      }, (error1) => {
        this.logger.error('Impossible d\'envoyer le message:', error1, formData);
        reject(this.errorBuilder(error1, 'Impossible d\'envoyer le message'));
      });
    });
  }

  deleteMessage(idMessage: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.messageService.deleteMessage(idMessage).subscribe(() => {
        this.logger.debug('Message suprim� :', idMessage);
        this.actualiseMessagesNonLus();
        resolve();
      }, (error1) => {
        this.logger.error('Impossible de suprimer le message ', idMessage);
        reject(this.errorBuilder(error1, 'Impossible de suprimer les message'));
      });
    });
  }

  actualiseMessagesNonLus() {
    this.messageService.derniersMessages().subscribe((value) => {
      this.logger.debug('Nombre de messages non lus :', value.nbMessageNonLu);
      this.nombreMessageNonLu$.next(value);
    }, () => {
      this.logger.debug('Impossible de r�cup�rer le nombre de messages non lus');
    });
  }

  reloadListeMessage(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.listeMessage().then(
        (value) => {
          // on post la valeur de le liste
          this.listeMessage$.next(value);
          resolve();
        },
      ).catch((reason) => {
        reject(reason);
      });
    });
  }

  private listeMessage(): Promise<ListeMessage> {
    return new Promise<ListeMessage>((resolve, reject) => {
      this.messageService.messages().subscribe((value) => {
        this.logger.debug('Liste des messages r�cup�r�s : ', value);
        resolve(value);
      }, (error1) => {
        this.logger.debug('Impossible de r�cup�rer la liste des messages : ', error1);
        reject(this.errorBuilder(error1, 'Impossible de r�cup�rer la liste des messages'));
      });
    });
  }
}
