import {Component, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-display-user',
  templateUrl: './display-user.component.html',
  styleUrls: ['./display-user.component.css'],
})
export class DisplayUserComponent implements OnInit {

  constructor(private userService: UserService, private logger: NGXLogger) {
  }

  ngOnInit() {
  }

  /**
   * Le nom complet de l'utilisateur connect�.
   */
  nomComplet(): string {
    return this.userService.user?.profil.nom + ' ' + this.userService.user?.profil.prenom;
  }

  /**
   * La date de deni�re connexion
   */
  derniereConnexion(): Date {
    if (this.userService.user) {
      return this.userService.user.derniereConnexion();
    }
    return null;
  }
  dernierDevice(): string {
    if (this.userService.user) {
      return this.userService.user.dernierDevice();
    }
    return null;
  }

  /**
   * Le libell� de la convention.
   */
  libelleConvention() {
    if (this.userService.user && this.userService.user.profil && this.userService.user.profil.typeProfil === 'delegue') {
      return this.userService.user.client.nomComplet;
    } else if (this.userService.isClientPrincipal()) {
      return 'Convention particulier';
    } else {
      if (this.userService.user && this.userService.user.client) {
        return this.userService.user.client.nomComplet;
      }
      return null;
    }
  }
}
