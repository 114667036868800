/**
 * Hoba/wesoc API 3.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';
import {Configuration} from '../conf/configuration';
import {BASE_PATH} from '../conf/variables';
import {CommandeChequier} from '../model/commandeChequier';
import {NumeroCompte} from '../model/numeroCompte';
import {DemandeAnr} from '../model/demandeAnr';
import {CommandeChequierListe} from '../model/commandeChequierListe';
import {OppositionCheque} from '../model/oppositionCheque';
import {DelivranceChequier} from '../model/delivranceChequier';


@Injectable()
export class ChequierService {

  protected basePath = 'https://www.socms.pf/hoba/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   *
   * Commande de ch�quiers sur un compte
   * @param body la commande de ch�quier
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public commandeChequier(body: CommandeChequier, numeroClient: string, numeroCompte: NumeroCompte, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;
  public commandeChequier(body: CommandeChequier, numeroClient: string, numeroCompte: NumeroCompte, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;
  public commandeChequier(body: CommandeChequier, numeroClient: string, numeroCompte: NumeroCompte, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;
  public commandeChequier(body: CommandeChequier, numeroClient: string, numeroCompte: NumeroCompte, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling commandeChequier.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling commandeChequier.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling commandeChequier.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<DemandeAnr>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/chequiers`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * R�cup�re les infos de d�livrance de ch�quier
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public delivranceChequier(numeroClient: string, numeroCompte: NumeroCompte, observe?: 'body', reportProgress?: boolean): Observable<DelivranceChequier>;
  public delivranceChequier(numeroClient: string, numeroCompte: NumeroCompte, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DelivranceChequier>>;
  public delivranceChequier(numeroClient: string, numeroCompte: NumeroCompte, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DelivranceChequier>>;
  public delivranceChequier(numeroClient: string, numeroCompte: NumeroCompte, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling delivranceChequier.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling delivranceChequier.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<DelivranceChequier>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/chequiers/delivrance`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Liste des commandes de ch�quiers sur un compte
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listeCommandeChequier(numeroClient: string, numeroCompte: NumeroCompte, observe?: 'body', reportProgress?: boolean): Observable<Array<CommandeChequierListe>>;
  public listeCommandeChequier(numeroClient: string, numeroCompte: NumeroCompte, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CommandeChequierListe>>>;
  public listeCommandeChequier(numeroClient: string, numeroCompte: NumeroCompte, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CommandeChequierListe>>>;
  public listeCommandeChequier(numeroClient: string, numeroCompte: NumeroCompte, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling listeCommandeChequier.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling listeCommandeChequier.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<CommandeChequierListe>>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/chequiers`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Opposition d&#x27;un ch�que
   * @param body l&#x27;opposition du cheque
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public oppositionCheque(body: OppositionCheque, numeroClient: string, numeroCompte: NumeroCompte, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;
  public oppositionCheque(body: OppositionCheque, numeroClient: string, numeroCompte: NumeroCompte, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;
  public oppositionCheque(body: OppositionCheque, numeroClient: string, numeroCompte: NumeroCompte, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;
  public oppositionCheque(body: OppositionCheque, numeroClient: string, numeroCompte: NumeroCompte, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling oppositionCheque.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling oppositionCheque.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling oppositionCheque.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<DemandeAnr>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/chequiers/opposition`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
