import {Injectable} from '@angular/core';
import {Categorie} from '../../api/model/categorie';
import {CoreServiceModule} from '../core-service.module';

@Injectable({
  providedIn: CoreServiceModule,
})
export class IconesService {
  /** Les icones. */
  private icones = {
    // TOUT
    UND: ['question', '', '#000000'],
    // les DEBIT
    ALI: ['cart', 'category_color7', '#9b00ff'],
    BIE: ['bienetre', 'category_color9', '#fdb441'],
    CAD: ['gift', 'category_color5', '#3134e0'],
    CON: ['consommation', 'category_color13', '#84253e'],
    ENE: ['energie', 'category_color15', '#818500'],
    ENF: ['enfant', 'category_color16', '#30861b'],
    EPA: ['epargne', 'category_color12', '#f98bc6'],
    LOG: ['house', 'category_color8', '#00bdff'],
    LOI: ['globe', 'category_color4', '#b1936e'],
    MUL: ['multimedia', 'category_color11', '#b0b0b0'],
    OPE: ['convert2', 'category_color6', '#916b28'],
    RET: ['files', 'category_color2', '#26a386'],
    SAN: ['like', 'category_color1', '#ff8c80'],
    SOR: ['sortie', 'category_color17', '#0072b4'],
    TRA: ['transport', 'category_color10', '#fd3904'],
    VAC: ['vacances', 'category_color14', '#df8300'],
    VOI: ['perso2', 'category_color3', '#8397ff'],
    // les CREDIT
    DIV: ['dividende', 'category_color22', '#7e1b30'],
    INT: ['interet', 'category_color20', '#553a85'],
    LOY: ['loyers', 'category_color21', '#75237b'],
    PEN: ['pensions', 'category_color24', '#207c57'],
    PRE: ['prestationssociales', 'category_color19', '#2d4982'],
    REM: ['remboursement', 'category_color18', '#2b8b88'],
    REN: ['rentes', 'category_color23', '#026283'],
    SAL: ['salaires', 'category_color25', '#ff00ac'],
  };

  constructor() {
  }

  /**
   * retourne la classe css de l'icone pour la cat�gorie
   * @param categorie le code de la cat�gorie
   */
  categorieIcon(categorie: Categorie): string {
    if (categorie) {
      return 'icon-' + this.icones[categorie.code][0];
    } else {
      return 'icon-' + this.icones['UND'][0];
    }
  }

  /**
   * retourne le suffixe css de la cat�gorie
   * @param categorie le code de la cat�gorie
   */
  categorieSuffixe(categorie: Categorie): string {
    if (categorie) {
      return this.icones[categorie.code][0];
    } else {
      return this.icones['UND'][0];
    }
  }

  /**
   * Retourne la class de couleur css de la cat�gorie
   * @param categorie le code cat�gorie
   */
  categorieClassCouleur(categorie: Categorie): string {
    if (categorie) {
      return this.icones[categorie.code][1];
    } else {
      return this.icones['UND'][1];
    }
  }

  /**
   * Retourne le code couleur '#xxxxxx' de la cat�gorie
   * @param categorie le code cat�gorie
   */
  categorieCodeCouleur(categorie: Categorie): string {
    if (categorie) {
      return this.icones[categorie.code][2];
    } else {
      return this.icones['UND'][2];
    }
  }

  /**
   * Retourne le libell� de la cat�gorie
   * @param categorie le code cat�gorie
   */
  categorieLibelle(categorie: Categorie): string {
    if (categorie) {
      return categorie.libelle;
    } else {
      return 'Non cat�goris�';
    }
  }
}
