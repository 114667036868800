class CategorieFooter {

  titre: string;

  liens: Array<[string, string]>;
}

export const FooterPublique: CategorieFooter[] =
  [
    {
      titre: 'Banque SOCREDO',
      liens: [
        ['Notre histoire', 'http://www.socredo.pf/notre-histoire'],
        ['Nos missions', 'http://www.socredo.pf/nos-missions'],
        ['Nos r�sultats', 'http://www.socredo.pf/nos-chiffres-cles'],
        ['Nos engagements', 'http://www.socredo.pf/nos-engagements'],
        ['A la Une', 'https://www.socredo.pf/a-la-une/en-bref'],
        ['Groupe SOCREDO', 'http://www.socredo.pf/le-groupe-socredo'],
        ['Nous rejoindre', 'http://www.socredo.pf/nous-rejoindre/offres-emploi'],
      ],
    },
    {
      titre: 'Produits et services',
      liens: [
        ['Particuliers', 'http://www.socredo.pf/particuliers'],
        ['Jeunes', 'http://www.socredo.pf/jeunes'],
        ['Casden', 'http://www.socredo.pf/casden/produit/offre-casden'],
        ['Professionnels', 'http://www.socredo.pf/professionnels'],
        ['Entreprises', 'http://www.socredo.pf/entreprises'],
        ['Association', 'https://www.socredo.pf/associations/ouvrir-un-compte'],
        ['Gestion Priv�e', 'http://www.socredo.pf/gestion-privee'],
      ],
    },
    {
      titre: 'Informations utiles',
      liens: [
        ['Mentions l�gales', 'http://www.socredo.pf/mentions-legales'],
        ['Donn�es personnelles', 'http://www.socredo.pf/donnees-personnelles'],
        ['Charte de m�diation', 'http://www.socredo.pf/charte-de-mediation'],
        ['Loi ECKERT', 'http://www.socredo.pf/loi-eckert'],
        ['FGDR', 'http://www.socredo.pf/fonds-de-garantie-des-depots-et-de-resolution'],
        ['S�curit�', 'http://www.socredo.pf/securite'],
      ],
    },
    {
      titre: 'Socredo pratique',
      liens: [
        ['Nous contacter', 'https://www.socredo.pf/contact'],
        ['Trouver une agence', 'http://www.socredo.pf/agences'],
        ['Simulateur ', 'https://www.socredo.pf/particuliers/credits-personnels#credit'],
        ['FAQ', 'http://www.socredo.pf/faq'],
        ['Alerte SOCMS', 'https://www.socms.pf/Socms/'],
        ['Cartes AMEX', 'https://www.americanexpress.pf/'],
      ],
    },
  ];
