/**
 * Hoba/wesoc API 3.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse,} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';

import {Observable} from 'rxjs';
import {Configuration} from '../conf/configuration';
import {CustomHttpUrlEncodingCodec} from '../conf/encoder';
import {BASE_PATH} from '../conf/variables';
import {DemandeAnr} from '../model/demandeAnr';
import {LigneTransfertFichier} from '../model/ligneTransfertFichier';
import {ListeTransferts} from '../model/listeTransferts';
import {NumeroCompte} from '../model/numeroCompte';
import {RechercheDetailTransfert} from '../model/rechercheDetailTransfert';
import {TypeTransfertEnum} from '../model/typeTransfertEnum';
import {Transfert} from '../model/transfert';

@Injectable()
export class TransfertService {

  protected basePath = 'https://www.socms.pf/hoba/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   *
   * Confirme l&#x27;envoi d&#x27;un transfert
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param idTransfert id du transfert de fichier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public confirmationTransfert(numeroClient: string, numeroCompte: NumeroCompte, idTransfert: number, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;
  public confirmationTransfert(numeroClient: string, numeroCompte: NumeroCompte, idTransfert: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;
  public confirmationTransfert(numeroClient: string, numeroCompte: NumeroCompte, idTransfert: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;
  public confirmationTransfert(numeroClient: string, numeroCompte: NumeroCompte, idTransfert: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling confirmationTransfert.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling confirmationTransfert.');
    }

    if (idTransfert === null || idTransfert === undefined) {
      throw new Error('Required parameter idTransfert was null or undefined when calling confirmationTransfert.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<DemandeAnr>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/transfertsFichiers/${encodeURIComponent(String(idTransfert))}/confirme`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Ajoute un transfert de fichier
   * @param typeTransfert
   * @param filename
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createTransfert(typeTransfert: TypeTransfertEnum, filename: Blob, numeroClient: string, numeroCompte: NumeroCompte, observe?: 'body', reportProgress?: boolean): Observable<Transfert>;
  public createTransfert(typeTransfert: TypeTransfertEnum, filename: Blob, numeroClient: string, numeroCompte: NumeroCompte, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Transfert>>;
  public createTransfert(typeTransfert: TypeTransfertEnum, filename: Blob, numeroClient: string, numeroCompte: NumeroCompte, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Transfert>>;
  public createTransfert(typeTransfert: TypeTransfertEnum, filename: Blob, numeroClient: string, numeroCompte: NumeroCompte, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (typeTransfert === null || typeTransfert === undefined) {
      throw new Error('Required parameter typeTransfert was null or undefined when calling createTransfert.');
    }

    if (filename === null || filename === undefined) {
      throw new Error('Required parameter filename was null or undefined when calling createTransfert.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling createTransfert.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling createTransfert.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'multipart/form-data'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void; };
    let useForm = false;
    let convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    }

    if (typeTransfert !== undefined) {
     formParams.append('typeTransfert', <any>typeTransfert);
    }
    if (filename !== undefined) {
      formParams.append('filename', <any>filename);
    }

    return this.httpClient.post<Transfert>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/transfertsFichiers`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * R�cup�re le d�tail d&#x27;un transfert
   * @param body les param�tres de recherche
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param idTransfert id du transfert de fichier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public detailTransfert(body: RechercheDetailTransfert, numeroClient: string, numeroCompte: NumeroCompte, idTransfert: number, observe?: 'body', reportProgress?: boolean): Observable<Array<LigneTransfertFichier>>;
  public detailTransfert(body: RechercheDetailTransfert, numeroClient: string, numeroCompte: NumeroCompte, idTransfert: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LigneTransfertFichier>>>;
  public detailTransfert(body: RechercheDetailTransfert, numeroClient: string, numeroCompte: NumeroCompte, idTransfert: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LigneTransfertFichier>>>;
  public detailTransfert(body: RechercheDetailTransfert, numeroClient: string, numeroCompte: NumeroCompte, idTransfert: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling detailTransfert.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling detailTransfert.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling detailTransfert.');
    }

    if (idTransfert === null || idTransfert === undefined) {
      throw new Error('Required parameter idTransfert was null or undefined when calling detailTransfert.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<Array<LigneTransfertFichier>>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/transfertsFichiers/${encodeURIComponent(String(idTransfert))}/details`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * remonte la liste des transferts de fichiers d&#x27;un compte
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listeTransferts(numeroClient: string, numeroCompte: NumeroCompte, observe?: 'body', reportProgress?: boolean): Observable<ListeTransferts>;
  public listeTransferts(numeroClient: string, numeroCompte: NumeroCompte, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListeTransferts>>;
  public listeTransferts(numeroClient: string, numeroCompte: NumeroCompte, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListeTransferts>>;
  public listeTransferts(numeroClient: string, numeroCompte: NumeroCompte, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling listeTransferts.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling listeTransferts.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ListeTransferts>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/transfertsFichiers`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * rejette un transfert en attente (Saisiseur-Valideur)
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param idTransfert id du transfert de fichier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rejetTransfert(numeroClient: string, numeroCompte: NumeroCompte, idTransfert: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public rejetTransfert(numeroClient: string, numeroCompte: NumeroCompte, idTransfert: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public rejetTransfert(numeroClient: string, numeroCompte: NumeroCompte, idTransfert: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public rejetTransfert(numeroClient: string, numeroCompte: NumeroCompte, idTransfert: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling rejetTransfert.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling rejetTransfert.');
    }

    if (idTransfert === null || idTransfert === undefined) {
      throw new Error('Required parameter idTransfert was null or undefined when calling rejetTransfert.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/transfertsFichiers/${encodeURIComponent(String(idTransfert))}/rejete`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * R�cup�re la synthese d&#x27;un transfert
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param idTransfert id du transfert de fichier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public syntheseTransfert(numeroClient: string, numeroCompte: NumeroCompte, idTransfert: number, observe?: 'body', reportProgress?: boolean): Observable<Transfert>;
  public syntheseTransfert(numeroClient: string, numeroCompte: NumeroCompte, idTransfert: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Transfert>>;
  public syntheseTransfert(numeroClient: string, numeroCompte: NumeroCompte, idTransfert: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Transfert>>;
  public syntheseTransfert(numeroClient: string, numeroCompte: NumeroCompte, idTransfert: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling syntheseTransfert.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling syntheseTransfert.');
    }

    if (idTransfert === null || idTransfert === undefined) {
      throw new Error('Required parameter idTransfert was null or undefined when calling syntheseTransfert.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Transfert>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/transfertsFichiers/${encodeURIComponent(String(idTransfert))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Valide un transfert en attente (Saisiseur-Valideur)
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param idTransfert id du transfert de fichier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public validationTransfert(numeroClient: string, numeroCompte: NumeroCompte, idTransfert: number, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;
  public validationTransfert(numeroClient: string, numeroCompte: NumeroCompte, idTransfert: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;
  public validationTransfert(numeroClient: string, numeroCompte: NumeroCompte, idTransfert: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;
  public validationTransfert(numeroClient: string, numeroCompte: NumeroCompte, idTransfert: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling validationTransfert.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling validationTransfert.');
    }

    if (idTransfert === null || idTransfert === undefined) {
      throw new Error('Required parameter idTransfert was null or undefined when calling validationTransfert.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<DemandeAnr>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/transfertsFichiers/${encodeURIComponent(String(idTransfert))}/valide`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
