<section class="modal"
         id="simple-popup" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
    <div class="modal-content position-relative py-5 px-3">
      <div class="modal-header"><a (click)="close.emit()" class="close-modal-btn"><i
        class="icon-close cursor-pointer"></i></a>
      </div>
      <div class="modal-body text-center px-3">
        <p class="pre-line">{{ bodyText }}</p>
      </div>
    </div>
  </div>
</section>
