/**
 * Cr�dit D�mat API
 * API pour Cr�dit D�mat - SOCREDO
 *
 * The version of the OpenAPI document: 1.00
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpContext, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpParameterCodec} from '../../conf/new/encoder';
import {Observable} from 'rxjs';

// @ts-ignore
import {DemandeCredit} from '../model/demandeCredit';
// @ts-ignore
import {DemandeCreditComplet} from '../model/demandeCreditComplet';
// @ts-ignore
import {DemandeCreditEtape4} from '../model/demandeCreditEtape4';
// @ts-ignore
import {DemandeCreditPaged} from '../model/demandeCreditPaged';
// @ts-ignore
import {Erreur} from '../model/erreur';

// @ts-ignore
import {BASE_PATH, COLLECTION_FORMATS} from '../../conf/new/variables';
import {Configuration} from '../../conf/new/configuration';


@Injectable({
  providedIn: 'root'
})
export class DemandeCreditApiService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;
  protected basePath = 'https://hoba-tests.socredo.pf/hoba3/api';

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string | string[], @Optional() configuration: Configuration) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
   * Annulation d\&#39;une demande de cr�dit
   * @param numeroClient num�ro du client
   * @param id id de l\&#39;objet
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public annulationDemandeCredit(numeroClient: string, id: number, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<DemandeCredit>;

  public annulationDemandeCredit(numeroClient: string, id: number, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<DemandeCredit>>;

  public annulationDemandeCredit(numeroClient: string, id: number, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<DemandeCredit>>;

  public annulationDemandeCredit(numeroClient: string, id: number, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {
    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling annulationDemandeCredit.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling annulationDemandeCredit.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerAuth) required
    localVarCredential = this.configuration.lookupCredential('bearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/clients/${this.configuration.encodeParam({
      name: 'numeroClient',
      value: numeroClient,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/demandesCredits/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: 'int64'
    })}/annulation`;
    return this.httpClient.request<DemandeCredit>('put', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Consultation d\&#39;une demande de cr�dit
   * @param numeroClient num�ro du client
   * @param id id de l\&#39;objet
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public consultationDemandeCredit(numeroClient: string, id: number, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<DemandeCreditComplet>;

  public consultationDemandeCredit(numeroClient: string, id: number, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<DemandeCreditComplet>>;

  public consultationDemandeCredit(numeroClient: string, id: number, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<DemandeCreditComplet>>;

  public consultationDemandeCredit(numeroClient: string, id: number, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {
    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling consultationDemandeCredit.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling consultationDemandeCredit.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerAuth) required
    localVarCredential = this.configuration.lookupCredential('bearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/clients/${this.configuration.encodeParam({
      name: 'numeroClient',
      value: numeroClient,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/demandesCredits/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: 'int64'
    })}`;
    return this.httpClient.request<DemandeCreditComplet>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Cr�ation demande de cr�dit
   * @param numeroClient num�ro du client
   * @param demandeCredit
   * @param justificatifIdentite
   * @param justificatifDomicile
   * @param justificatifRevenu1
   * @param justificatifRevenu2
   * @param justificatifRevenu3
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createDemandeCredit(numeroClient: string, demandeCredit: DemandeCredit, justificatifIdentite: Blob, justificatifDomicile?: Blob, justificatifRevenu1?: Blob, justificatifRevenu2?: Blob, justificatifRevenu3?: Blob, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<DemandeCredit>;

  public createDemandeCredit(numeroClient: string, demandeCredit: DemandeCredit, justificatifIdentite: Blob, justificatifDomicile?: Blob, justificatifRevenu1?: Blob, justificatifRevenu2?: Blob, justificatifRevenu3?: Blob, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<DemandeCredit>>;

  public createDemandeCredit(numeroClient: string, demandeCredit: DemandeCredit, justificatifIdentite: Blob, justificatifDomicile?: Blob, justificatifRevenu1?: Blob, justificatifRevenu2?: Blob, justificatifRevenu3?: Blob, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<DemandeCredit>>;

  public createDemandeCredit(numeroClient: string, demandeCredit: DemandeCredit, justificatifIdentite: Blob, justificatifDomicile?: Blob, justificatifRevenu1?: Blob, justificatifRevenu2?: Blob, justificatifRevenu3?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {
    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling createDemandeCredit.');
    }
    if (demandeCredit === null || demandeCredit === undefined) {
      throw new Error('Required parameter demandeCredit was null or undefined when calling createDemandeCredit.');
    }
    if (justificatifIdentite === null || justificatifIdentite === undefined) {
      throw new Error('Required parameter justificatifIdentite was null or undefined when calling createDemandeCredit.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerAuth) required
    localVarCredential = this.configuration.lookupCredential('bearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'multipart/form-data'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let localVarFormParams: { append(param: string, value: any): any; };
    let localVarUseForm = false;
    let localVarConvertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    if (localVarUseForm) {
      localVarFormParams = new FormData();
    } else {
      localVarFormParams = new HttpParams({encoder: this.encoder});
    }

    if (demandeCredit !== undefined) {
      localVarFormParams = localVarFormParams.append('demandeCredit', localVarUseForm ? new Blob([JSON.stringify(demandeCredit)], {type: 'application/json'}) : <any>demandeCredit) as any || localVarFormParams;
    }
    if (justificatifIdentite !== undefined) {
      localVarFormParams = localVarFormParams.append('justificatifIdentite', <any>justificatifIdentite) as any || localVarFormParams;
    }
    if (justificatifDomicile !== undefined) {
      localVarFormParams = localVarFormParams.append('justificatifDomicile', <any>justificatifDomicile) as any || localVarFormParams;
    }
    if (justificatifRevenu1 !== undefined) {
      localVarFormParams = localVarFormParams.append('justificatifRevenu1', <any>justificatifRevenu1) as any || localVarFormParams;
    }
    if (justificatifRevenu2 !== undefined) {
      localVarFormParams = localVarFormParams.append('justificatifRevenu2', <any>justificatifRevenu2) as any || localVarFormParams;
    }
    if (justificatifRevenu3 !== undefined) {
      localVarFormParams = localVarFormParams.append('justificatifRevenu3', <any>justificatifRevenu3) as any || localVarFormParams;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/clients/${this.configuration.encodeParam({
      name: 'numeroClient',
      value: numeroClient,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/demandesCredits/creation`;
    return this.httpClient.request<DemandeCredit>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Liste des demandes de cr�dits
   * @param numeroClient num�ro du client
   * @param page page pour la recherche pagin�e
   * @param size nb d\&#39;enregistrements � retourner pour la recherche pagin�e
   * @param sort tri � faire pour la recherche pagin�e
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listeDemandeCredit(numeroClient: string, page: number, size: number, sort?: string, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<DemandeCreditPaged>;

  public listeDemandeCredit(numeroClient: string, page: number, size: number, sort?: string, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<DemandeCreditPaged>>;

  public listeDemandeCredit(numeroClient: string, page: number, size: number, sort?: string, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<DemandeCreditPaged>>;

  public listeDemandeCredit(numeroClient: string, page: number, size: number, sort?: string, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {
    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling listeDemandeCredit.');
    }
    if (page === null || page === undefined) {
      throw new Error('Required parameter page was null or undefined when calling listeDemandeCredit.');
    }
    if (size === null || size === undefined) {
      throw new Error('Required parameter size was null or undefined when calling listeDemandeCredit.');
    }

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>page, 'page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>size, 'size');
    }
    if (sort !== undefined && sort !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>sort, 'sort');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerAuth) required
    localVarCredential = this.configuration.lookupCredential('bearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/clients/${this.configuration.encodeParam({
      name: 'numeroClient',
      value: numeroClient,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/demandesCredits/liste`;
    return this.httpClient.request<DemandeCreditPaged>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * R�cup�ration du contrat sign�
   * @param numeroClient num�ro du client
   * @param id id de l\&#39;objet
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public recuperationContratSigne(numeroClient: string, id: number, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/octet-stream' | 'application/json',
    context?: HttpContext
  }): Observable<Blob>;

  public recuperationContratSigne(numeroClient: string, id: number, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/octet-stream' | 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<Blob>>;

  public recuperationContratSigne(numeroClient: string, id: number, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/octet-stream' | 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<Blob>>;

  public recuperationContratSigne(numeroClient: string, id: number, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/octet-stream' | 'application/json',
    context?: HttpContext
  }): Observable<any> {
    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling recuperationContratSigne.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling recuperationContratSigne.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerAuth) required
    localVarCredential = this.configuration.lookupCredential('bearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/octet-stream',
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    let localVarPath = `/clients/${this.configuration.encodeParam({
      name: 'numeroClient',
      value: numeroClient,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/demandesCredits/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: 'int64'
    })}/contratSigne`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: 'blob',
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Mise � jour des pi�ces jointes
   * @param numeroClient num�ro du client
   * @param id id de l\&#39;objet
   * @param etape4
   * @param justificatifIdentite
   * @param justificatifDomicile
   * @param justificatifRevenu1
   * @param justificatifRevenu2
   * @param justificatifRevenu3
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updatePjDemandeCredit(numeroClient: string, id: number, etape4?: DemandeCreditEtape4, justificatifIdentite?: Blob, justificatifDomicile?: Blob, justificatifRevenu1?: Blob, justificatifRevenu2?: Blob, justificatifRevenu3?: Blob, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any>;

  public updatePjDemandeCredit(numeroClient: string, id: number, etape4?: DemandeCreditEtape4, justificatifIdentite?: Blob, justificatifDomicile?: Blob, justificatifRevenu1?: Blob, justificatifRevenu2?: Blob, justificatifRevenu3?: Blob, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<any>>;

  public updatePjDemandeCredit(numeroClient: string, id: number, etape4?: DemandeCreditEtape4, justificatifIdentite?: Blob, justificatifDomicile?: Blob, justificatifRevenu1?: Blob, justificatifRevenu2?: Blob, justificatifRevenu3?: Blob, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<any>>;

  public updatePjDemandeCredit(numeroClient: string, id: number, etape4?: DemandeCreditEtape4, justificatifIdentite?: Blob, justificatifDomicile?: Blob, justificatifRevenu1?: Blob, justificatifRevenu2?: Blob, justificatifRevenu3?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {
    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling updatePjDemandeCredit.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updatePjDemandeCredit.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerAuth) required
    localVarCredential = this.configuration.lookupCredential('bearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'multipart/form-data'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let localVarFormParams: { append(param: string, value: any): any; };
    let localVarUseForm = false;
    let localVarConvertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    if (localVarUseForm) {
      localVarFormParams = new FormData();
    } else {
      localVarFormParams = new HttpParams({encoder: this.encoder});
    }

    if (etape4 !== undefined) {
      localVarFormParams = localVarFormParams.append('etape4', localVarUseForm ? new Blob([JSON.stringify(etape4)], {type: 'application/json'}) : <any>etape4) as any || localVarFormParams;
    }
    if (justificatifIdentite !== undefined) {
      localVarFormParams = localVarFormParams.append('justificatifIdentite', <any>justificatifIdentite) as any || localVarFormParams;
    }
    if (justificatifDomicile !== undefined) {
      localVarFormParams = localVarFormParams.append('justificatifDomicile', <any>justificatifDomicile) as any || localVarFormParams;
    }
    if (justificatifRevenu1 !== undefined) {
      localVarFormParams = localVarFormParams.append('justificatifRevenu1', <any>justificatifRevenu1) as any || localVarFormParams;
    }
    if (justificatifRevenu2 !== undefined) {
      localVarFormParams = localVarFormParams.append('justificatifRevenu2', <any>justificatifRevenu2) as any || localVarFormParams;
    }
    if (justificatifRevenu3 !== undefined) {
      localVarFormParams = localVarFormParams.append('justificatifRevenu3', <any>justificatifRevenu3) as any || localVarFormParams;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/clients/${this.configuration.encodeParam({
      name: 'numeroClient',
      value: numeroClient,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/demandesCredits/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: 'int64'
    })}/majPiecesJointes`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
          httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

}
