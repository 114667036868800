/***
 * Classe stockant les informations de l'utilisateur connect�.
 */
import {Client} from '../../api/model/client';
import {Profil} from '../../api/model/profil';
import {DroitEnum} from '../../api/model/droitEnum';
import {EtatCompletude} from '../../api/kyc/model/etatCompletude';
import TypeAbonnementEnum = Client.TypeAbonnementEnum;

export class User {
  /** la page synthese a t'elle d�j� �t� visit�e pour le tutoriel ? */
  public isPremiereVisiteSynthese: boolean;
  /** la page Liste des comptes a t'elle d�j� �t� visit�e pour le tutoriel ? */
  public isPremiereVisiteListe: boolean;
  /** la page d�tail d'un compte a t'elle d�j� �t� visit�e pour le tutoriel ? */
  public isPremiereVisiteDetail: boolean;
  /** l'utilisateur est-il celui de d�mo ? */
  public isDemo = false;
  /** Token fournis par le WebService � l'utilisateur lors de son authentification */
  private readonly _token: string;
  /** Num�ro d'abonn� */
  private readonly _numeroAbonne: string;

  /**
   * Constructeur.
   * @param token le token de l'utilisateur
   * @param numeroAbonne le num�ro d'abonn�
   */
  constructor(token?: string, numeroAbonne?: string) {
    this._token = token;
    this._numeroAbonne = numeroAbonne
  }

  get token(): string {
    return this._token;
  }

  get numeroAbonne(): string {
    return this._numeroAbonne;
  }

  /** Profils. */
  private _profil: Profil;

  get profil(): Profil {
    return this._profil;
  }

  set profil(profil: Profil) {
    this._profil = profil;
  }

  /** Client s�lectionn�. */
  private _client: Client;

  get client(): Client {
    return this._client;
  }

  set client(client: Client) {
    this._client = client;
  }

  /** Profils. */
  private _etatCompletude: EtatCompletude;

  get etatCompletude(): EtatCompletude {
    return this._etatCompletude;
  }

  set etatCompletude(etatCompletude: EtatCompletude) {
    this._etatCompletude = etatCompletude;
  }

  /**
   * @return la date de derni�re connexion de l'utilisateur
   */
  derniereConnexion(): Date {
    if (this._profil.derniersAcces[1]) {
      return this._profil.derniersAcces[1].date;
    } else {
      return this._profil.derniersAcces[0].date;
    }
  }

  dernierDevice(): string {
    if (this._profil.derniersAcces[1]) {
      return this._profil.derniersAcces[1].device;
    } else {
      return this._profil.derniersAcces[0].device;
    }
  }

  /**
   * A-t-il un acc�s en consultation sur ce compte?
   * @param numeroCompte le compte concern�
   */
  public isConsultation(numeroCompte: string): boolean {
    return this.isComplet() || !!this.profil.droits.find((value) => {
      return value.numeroCompte === numeroCompte;
    });
  }

  public isSaisisseur(numeroCompte: string): boolean {
    return this.isComplet() || !!this.profil.droits.find((value) => {
      return value.numeroCompte === numeroCompte &&
        (value.droit === DroitEnum.Saisisseur || value.droit === DroitEnum.SaisisseurValideur);
    });
  }

  public isValideur(numeroCompte: string): boolean {
    return this.isComplet() || !!this.profil.droits.find((value) => {
      return value.numeroCompte === numeroCompte &&
        (value.droit === DroitEnum.Valideur || value.droit === DroitEnum.SaisisseurValideur);
    });
  }

  /**
   * A-t-il tous les droits : soit un adh�rent, soit un d�l�gu� avec acc�s complet.
   */
  public isComplet(): boolean {
    return (this.profil?.typeProfil === 'adherent' || this.profil?.accesComplet);
  }

  /**
   * A-t-il au moins un droit saisisseur?
   */
  public hasDroitSaisisseur(): boolean {
    return this.isComplet() || !!this.profil.droits.find((value) => {
      return (value.droit === DroitEnum.Saisisseur || value.droit === DroitEnum.SaisisseurValideur);
    });
  }

  /**
   * A-t-il au moins un droit valideur?
   */
  public hasDroitValideur(): boolean {
    return this.isComplet() || !!this.profil.droits.find((value) => {
      return (value.droit === DroitEnum.Valideur || value.droit === DroitEnum.SaisisseurValideur);
    });
  }

  isPremium(): boolean {
    return this.client.typeAbonnement === TypeAbonnementEnum.Premium;
  }
}
