import {CommonModule} from '@angular/common';
import {NgModule, Optional, SkipSelf} from '@angular/core';

/**
 * C'est module qui ne g�re que les services pour des raisons de lazy et circular references.
 * https://medium.com/@tomastrajan/total-guide-to-angular-6-dependency-injection-providedin-vs-providers-85b7a347b59f
 * Il faut le d�clarer uniquement dans AppModule car sinon les services ne sont pas des singletons!
 */

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
})
export class CoreServiceModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreServiceModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import CoreService module in the AppModule only.`);
    }
  }
}
