<ng-container *ngIf="!erreur else Erreur">
  <section class="mot_de_passe_section">
    <div class="container py-5">
      <form (validSubmit)="onSubmit()" [formGroup]="formGroup" id="mot_de_passe_form">

        <ng-container *ngIf="userService.user.profil.statut === 'REINITIALISATION_MDP'">
          <div class="block_info mb-1">
            <p class="note_info">
              <i class="fas fa-exclamation  pe-2   "></i>
              <span>Vous consultez vos comptes depuis plus de 90 jours,
              par mesure de s�curit� et conform�ment � la r�glementation en vigeur nous devons vous
              authentifier par un autre moyen que vos identifiants de connexion habituels.
              Pour cela nous vous proposons de modifier votre mot de passe ou de vous authentifier
              par la r�ception d'un code d'authentification.</span>
            </p>
          </div>
        </ng-container>


        <div class="block_info mb-5">
          <p class="note_info infos_mdp_piti "><i
            class="fas fa-exclamation-circle pe-2 default-color"></i><span>Votre mot de passe doit comporter au minimum 10 caract�res et respecter les r�gles suivantes :</span>
          </p>
          <ul class="infos_mdp_piti">
            <li>Comporter au moins 3 caract�res distincts</li>
            <li>Ne pas comporter de date de naissance au format JJMMAA ou AAMMJJ</li>
            <li>Comporter au moins un chiffre, une majuscule et un caract�re sp�cial (ex: %,/!?)�-_\�#.)</li>
          </ul>
          <p class="pt-2"><a (click)="infosMdpComponent.toggleModal()" data-modal="moreinfo-modal">Comment faire en pratique</a></p>
        </div>
        <div class="block_content max-width600 text-center ">
          <p class="mb-4">Je souhaite modifier mon mot de passe

          </p>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label" for="password">Mot de passe actuel :</label>
            <div class="col-sm-6 position-relative">
              <input appForceAutoFocus class="form-control" formControlName="ancienMdp" id="password" name="password"
                     autocomplete="current-password" placeholder="Entrer votre mot de passe actuel"
                     type="password">
              <bfv-messages></bfv-messages>
              <button class="btn btn-outline-secondary reset_form_password" type="button">
                <i (click)="formGroup.controls.ancienMdp.setValue(null)" class="icon-close"></i>
              </button>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label" for="new_password">Nouveau mot de passe :</label>
            <div class="col-sm-6">
              <input class="form-control" formControlName="nouveauMdp" id="new_password" name="new_password"
                     autocomplete="new-password" placeholder="Entrer votre nouveau mot de passe"
                     type="password">
              <bfv-messages></bfv-messages>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label" for="confirm_password">Confirmation nouveau mot de passe :</label>
            <div class="col-sm-6">
              <input class="form-control" formControlName="confirmation" id="confirm_password" name="confirm_password"
                     autocomplete="new-password" placeholder="Entrer votre nouveau mot de passe"
                     type="password">
              <bfv-messages></bfv-messages>
              <div
                *ngIf="formGroup.controls.confirmation.touched && formGroup.controls.confirmation.hasError('confirmation')"
                class="invalid-feedback d-block  col-sm-6">
                {{formGroup.controls.confirmation.errors.confirmation}}
              </div>
            </div>

          </div>
          <div class="form-group row">
            <div class="col-sm-12 text-center">
              <button [ladda]="isLoading" class="btn btn-outline-primary" id="password_submit"
                      type="submit">Valider
              </button>
            </div>
          </div>
          <ng-container *ngIf="userService.user?.profil.statut === 'REINITIALISATION_MDP'">
            <p class="mb-4 mt-5">Je ne souhaite pas modifier mon mot de passe</p>
            <button [ladda]="isLoading" class="btn btn-outline-primary" type="button" (click)="continuerSansChanger()"
            >M'authentifier par un code � usage unique
            </button>
          </ng-container>
        </div>
      </form>
    </div>
  </section>
</ng-container>

<ng-template #Erreur>
  <div class="container">
    <app-message-erreur [erreur]="erreur"></app-message-erreur>
  </div>
</ng-template>

<app-infos-mdp #infosMdpComponent></app-infos-mdp>
