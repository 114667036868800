import {HttpClient} from '@angular/common/http';
import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {AssuranceService} from './api/assurance.service';
import {BeneficiaireService} from './api/beneficiaire.service';
import {BICService} from './api/bIC.service';
import {CarteService} from './api/carte.service';
import {CategorieService} from './api/categorie.service';
import {ClientService} from './api/client.service';
import {CompteService} from './api/compte.service';
import {CreditService} from './api/credit.service';
import {MessageService} from './api/message.service';
import {MotDePasseService} from './api/motDePasse.service';
import {MouvementService} from './api/mouvement.service';
import {PaysApiService} from './api/pays-api.service';
import {RIBService} from './api/rIB.service';
import {UtilisateurService} from './api/utilisateur.service';
import {VirementService} from './api/virement.service';
import {Configuration} from './conf/configuration';
import {AlerteService} from './api/alerte.service';
import {ChequierService} from './api/chequier.service';
import {AgenceService} from './api/agence.service';
import {TransfertService} from './api/transfert.service';
import {RelevesService} from './api/releves.service';
import {QuestionsService} from './site-insti/api/questions.service';
import {BudgetService} from './api/budget.service';
import {ParametreService} from './api/parametre.service';
import {HomeService} from './api/home.service';
import {SimulateurApiService} from './api/credit/simulateurApi.service';
import {ProfileApiService} from './api/credit/profileApi.service';
import {DemandeCreditApiService} from './api/credit/demandeCreditApi.service';
import {AuthentificationApiService} from './api/authentificationApi.service';
import {DossierClientApiService} from './kyc/api/dossierClientApi.service';
import {ANRApiService} from './api/aNRApi.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    AuthentificationApiService,
    ClientService,
    CarteService,
    ANRApiService,
    ChequierService,
    AgenceService,
    CompteService,
    VirementService,
    AlerteService,
    CreditService,
    AssuranceService,
    MotDePasseService,
    TransfertService,
    MouvementService,
    MessageService,
    RIBService,
    PaysApiService,
    BICService,
    BeneficiaireService,
    CategorieService,
    UtilisateurService,
    RelevesService,
    QuestionsService,
    BudgetService,
    ParametreService,
    HomeService,
    DemandeCreditApiService,
    SimulateurApiService,
    ProfileApiService,
    DossierClientApiService
  ],
})
export class ApiModule {
  constructor(@Optional() @SkipSelf() parentModule: ApiModule,
              @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
        'See also https://github.com/angular/angular/issues/20575');
    }
  }

  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{provide: Configuration, useFactory: configurationFactory}],
    };
  }
}
