import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs';
import {UserService} from '../services/user.service';
import {environment} from '../../../environments/environment';

/**
 * Intercepteur HTTP qui ajoute l'authorisation si l'utilisateur est connect�.
 * https://medium.com/@ryanchenkie_40935/angular-authentication-using-the-http-client-and-http-interceptors-2f9d1540eb8
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public userService: UserService, private logger: NGXLogger) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.logger.trace('Requ�tes', request.url);
    if (request.url.startsWith(environment.urlApi) || request.url.startsWith(environment.urlKycsiCol)) {
      if (this.userService.isConnected()) {
        this.logger.trace('On ajoute le Bearer token pour les requ�tes hoba uniquement');
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.userService.getToken()}`,
          },
        });
      }
    }
    return next.handle(request);
  }
}
