// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {NgxLoggerLevel} from 'ngx-logger';

export const environment = {
  production: true,
  version: require('../../package.json').version,
  urlsInstitutionelles: {
    siteInstitutionel: 'http://www.socredo.pf',
    devenirClient: 'http://www.socredo.pf/Accueil/Ouvrir_un_compte',
    bonnesPratiques: 'http://www.socredo.pf/Accueil/Banque_pratique/Banque_pratique/p',
    tarifs: 'https://www.socredo.pf/tarifs',
    mentionsLegales: 'http://www.socredo.pf/mentions-legales',
    donneesPersonnelles: 'http://www.socredo.pf/donnees-personnelles',
    agences: 'https://www.socredo.pf/agences',
    contact: 'https://www.socredo.pf/contact',
    simulateur: 'https://www.socredo.pf/particuliers/credits-personnels#credit',
    devises: 'https://www.socredo.pf/devises',
    linkedin: 'https://www.linkedin.com/company/banque-socredo',
    youtube: 'https://www.youtube.com/channel/UCZPK729yYZqhJAslDFfWpsQ',
    instagram: 'https://www.instagram.com/banque_socredo/',
    facebook: 'https://fr-fr.facebook.com/SOCREDO.PF/',
    cartes: 'https://www.socredo.pf/particuliers/cartes-bancaires',
    credits: 'https://www.socredo.pf/particuliers/credits-personnels',
    assurances: 'https://www.socredo.pf/particuliers/assurances',
    offres_groupees: 'https://www.socredo.pf/particuliers/offres',
    retractation: 'https://www.socredo.pf/uploads/documents/cards/formulaire-de-retractation-5e6bf5044d7b8420809315.pdf',
    doc_import_beneficiaire: 'https://www.socredo.pf/uploads/documents/cards/guide-de-saisie-ajout-beneficiaires-5ecf18f65c10b080265186.pdf',
  },
  recaptchaV3Publique: '6Lf_BngUAAAAAJFpFv1XfUdfwsQca2xczwtL7qGI',
  googleAnalytics: 'UA-159291053-1',
  urlApi: 'https://hoba-tests.socredo.pf/hoba3/api',
  urlSiteInstitutionelApi: 'https://www.socredo.pf/api',
  urlKycsiCol: 'https://hoba-tests.socredo.pf/kycsi-col',
  logLevel: NgxLoggerLevel.DEBUG,
  creditDemat: true,
  accrocheSocSecure: '1.3',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
