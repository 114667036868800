import {Injectable} from '@angular/core';
import {Client} from '../../api/model/client';
import {SyntheseCompte} from '../../api/model/syntheseCompte';
import {SyntheseCredit} from '../../api/model/syntheseCredit';
import {CoreServiceModule} from '../core-service.module';
import {AccesService, EnumFonctionnalite} from './acces.service';
import {UserService} from './user.service';
import TypeAbonnementEnum = Client.TypeAbonnementEnum;

export interface IAccesUserService {

  /**
   * Le client actif de l'utilisateur connect� a-t-il acc�s � cet fonctionalit�?
   *
   * @param fonctionalite la fonctionnalit� demand�e
   * @return true, s'il a acc�s, false sinon
   */
  hasAcces(fonctionalite: EnumFonctionnalite): boolean;

  /**
   * Peut-il afficher le lien vers les virements?
   *
   * @param compte le compte concern�
   * @return true s'il peut l'afficher, false sinon
   */
  canAfficheLienVirement(compte: SyntheseCompte): boolean;

  /**
   * Peut-il afficher le lien vers les ROB?
   *
   * @param compte le compte concern�
   * @return true s'il peut l'afficher, false sinon
   */
  canAfficheLienRib(compte: SyntheseCompte): boolean;

  /**
   * Peut-il afficher le lien vers les relev�s?
   *
   * @param compte le compte concern�
   * @return true s'il peut l'afficher, false sinon
   */
  canAfficheLienReleve(compte: SyntheseCompte): boolean;

  /**
   * Peut-il afficher le lien vers le compte?
   *
   * @return true s'il peut l'afficher, false sinon
   */
  canAfficheLienCompte(compte: SyntheseCompte): boolean;

  /**
   * Peut-il afficher le lien vers le cr�dit?
   *
   * @return true s'il peut l'afficher, false sinon
   */
  canAfficheLienCredit(credit: SyntheseCredit): boolean;

  /**
   * Peut-il afficher le lien vers l'assurance?
   *
   * @return true s'il peut l'afficher, false sinon
   */
  canAfficheLienAssurance(assurance: SyntheseCompte): boolean;

  /**
   * Est-ce un abonnement free?
   *
   * @return true si l'utilisateur utilise un client ayant un abonnement free
   */
  hasAbonnementFree(): boolean;

  /**
   * Est-ce un abonnement Uru?
   *
   * @return true si l'utilisateur utilise un client ayant un abonnement Uru
   */
  hasAbonnementUru(): boolean;

  /**
   * Est-ce un abonnement Light?
   *
   * @return true si l'utilisateur utilise un client ayant un abonnement Light
   */
  hasAbonnementLight(): boolean;
}


/**
 * Service d'acc�s pour un utilisateur.
 */
@Injectable({
  providedIn: CoreServiceModule,
})
export class AccesUserService implements IAccesUserService {

  constructor(private readonly userService: UserService, private readonly accesService: AccesService) {
  }

  /**
   * Le client actif de l'utilisateur connect� a-t-il acc�s � cet fonctionalit�?
   *
   * @param fonctionalite la fonctionnalit� demand�e
   * @return true, s'il a acc�s, false sinon
   */
  hasAcces(fonctionalite: EnumFonctionnalite): boolean {
    return this.accesService.hasAcces(fonctionalite, this.userService.clientActif().typeAbonnement);
  }

  /**
   * Peut-il afficher le lien vers les virements?
   *
   * @param compte le compte concern�
   * @return true s'il peut l'afficher, false sinon
   */
  canAfficheLienVirement(compte: SyntheseCompte): boolean {
    return compte.virement && this.hasAcces(EnumFonctionnalite.VIREMENT) && this.userService.user.isSaisisseur(compte.id);
  }

  /**
   * Peut-il afficher le lien vers les ROB?
   *
   * @param compte le compte concern�
   * @return true s'il peut l'afficher, false sinon
   */
  canAfficheLienRib(compte: SyntheseCompte): boolean {
    return compte.rib && this.hasAcces(EnumFonctionnalite.RIB);
  }

  /**
   * Peut-il afficher le lien vers les relev�s?
   *
   * @return true s'il peut l'afficher, false sinon
   */
  canAfficheLienReleve(compte: SyntheseCompte): boolean {
    return this.hasAcces(EnumFonctionnalite.RELEVES) && compte.releves;
  }

  /**
   * Peut-il afficher le lien vers le compte?
   *
   * @return true s'il peut l'afficher, false sinon
   */
  canAfficheLienCompte(compte: SyntheseCompte): boolean {
    return compte.consultable && this.hasAcces(EnumFonctionnalite.CONSULTATION_COMPTE);
  }

  /**
   * Peut-il afficher le lien vers le cr�dit?
   *
   * @return true s'il peut l'afficher, false sinon
   */
  canAfficheLienCredit(credit: SyntheseCredit): boolean {
    return credit.consultable && this.hasAcces(EnumFonctionnalite.DETAIL_CREDIT);
  }

  /**
   * Peut-il afficher le lien vers l'assurance?
   *
   * @return true s'il peut l'afficher, false sinon
   */
  canAfficheLienAssurance(assurance: SyntheseCompte): boolean {
    return assurance.consultable && this.hasAcces(EnumFonctionnalite.DETAIL_ASSURANCE);
  }

  /**
   * Est-ce un abonnement free?
   *
   * @return true si l'utilisateur utilise un client ayant un abonnement free
   */
  hasAbonnementFree(): boolean {
    if (this.userService.clientActif()) {
      return this.userService.clientActif().typeAbonnement === TypeAbonnementEnum.Free;
    }
    return false;
  }

  /**
   * Est-ce un abonnement Uru?
   *
   * @return true si l'utilisateur utilise un client ayant un abonnement Uru
   */
  hasAbonnementUru(): boolean {
    if (this.userService.clientActif()) {
      return this.userService.clientActif().typeAbonnement === TypeAbonnementEnum.Uru;
    }
    return false;
  }

  hasAbonnementLight() {
    if (this.userService.clientActif()) {
      return this.userService.clientActif().typeAbonnement === TypeAbonnementEnum.Light;
    }
    return false;
  }
}
