<app-base-header [titre]="titre"></app-base-header>

<main class="main" *ngIf="!erreur else Erreur ">

  <ng-content></ng-content>

  <section class="anr_form_section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 pb-4">
          <div class="anr_block py-5">

            <div class="text-center align-items-center p-4">
              <img alt="smartphone recevant un code sms" height="128" src="assets/images/laptop-loading.gif" width="128"/>
            </div>
            <div class="anr_title_form text-center ">Validation de votre demande</div>
            <p class="text-center py-2">Veuillez valider cette op�ration, dans les 5 minutes,<br/>
              gr�ce � l'application mobile <img src="assets/images/laptop-logo.png" class="align-top" alt="SOCSECURE" height="15"/> sur votre appareil <b>{{demandeAnr.telephoneMasque}}</b>.</p>

            <div class="text-center align-items-center pt-2 d-none d-md-block">
              <img src="assets/images/laptop-etapes.jpg" alt="#" height="100" />
            </div>
            <div class="text-center align-items-center pt-2 d-block d-md-none">
              <img src="assets/images/mobile-etapes.gif" alt="#" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<ng-template #Erreur>
  <app-message-erreur [erreur]="erreur"></app-message-erreur>
</ng-template>

