import {Directive, OnInit} from '@angular/core';
import {Subscription} from "rxjs";

/**
 * classe g�n�rique pour les composants
 */
@Directive()
export class BaseComponent implements OnInit {
  /** la page est en chargement */
  isLoading: boolean;
  /** un submit est en cours */
  isSubmit = false;
  /** message d'erreur survenue */
  erreur: string;
  /** les subscriptions de la page (pour les observables non HTTP notamment) */
  subscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.isLoading = true;
  }

  /**
   * Gestion des erreurs.
   *
   * @param erreur erreur se produisant
   */
  handleErreur(erreur: any): void {
    this.erreur = this.erreur = erreur?.message ?? erreur;
    this.isLoading = false; //on arr�te tout chargement et on affiche une erreur
    this.isSubmit = false;
  }
}
