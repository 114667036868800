/**
 * Hoba/wesoc API 3.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {Observable} from 'rxjs';
import {Configuration} from '../conf/configuration';
import {CustomHttpUrlEncodingCodec} from '../conf/encoder';
import {BASE_PATH} from '../conf/variables';
import {BorneMinMax} from '../model/borneMinMax';
import {Compte} from '../model/compte';
import {CompteListe} from '../model/compteListe';
import {EncoursCarteCompte} from '../model/encoursCarteCompte';
import {EvolutionSolde} from '../model/evolutionSolde';
import {Flux} from '../model/flux';
import {Mouvement} from '../model/mouvement';
import {NumeroCompte} from '../model/numeroCompte';
import {OperationsCarteCompte} from '../model/operationsCarteCompte';
import {RechercheMouvement} from '../model/rechercheMouvement';
import {RechercheVirement} from '../model/rechercheVirement';
import {Repartition} from '../model/repartition';
import {TauxCompte} from '../model/tauxCompte';
import {TypeListeCompteEnum} from '../model/typeListeCompteEnum';
import {VirementListe} from '../model/virementListe';
import {VirementPermanentListe} from '../model/virementPermanentListe';

@Injectable()
export class CompteService {

  protected basePath = 'https://www.socms.pf/hoba/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * R�cup�re le d�tail d&#x27;un compte courant, �pargne ou devise
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public compte(numeroClient: string, numeroCompte: string, observe?: 'body', reportProgress?: boolean): Observable<Compte>;
  public compte(numeroClient: string, numeroCompte: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Compte>>;
  public compte(numeroClient: string, numeroCompte: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Compte>>;
  public compte(numeroClient: string, numeroCompte: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling compte.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling compte.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Compte>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * R�cup�re l&#x27;encours carte des X derniers mois d&#x27;un compte
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param toutes
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public encoursCarteCompte(numeroClient: string, numeroCompte: string, toutes?: boolean, observe?: 'body', reportProgress?: boolean): Observable<EncoursCarteCompte[]>;
  public encoursCarteCompte(numeroClient: string, numeroCompte: string, toutes?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EncoursCarteCompte[]>>;
  public encoursCarteCompte(numeroClient: string, numeroCompte: string, toutes?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EncoursCarteCompte[]>>;
  public encoursCarteCompte(numeroClient: string, numeroCompte: string, toutes?: boolean, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling encoursCarteCompte.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling encoursCarteCompte.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (toutes !== undefined && toutes !== null) {
      queryParameters = queryParameters.set('toutes', toutes as any);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<EncoursCarteCompte[]>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/encours`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * L&#x27;�volution du solde
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param dateDebut date de d�but au format yyyy-mm-dd (maximum 90 jours)
   * @param dateFin date de fin au format yyyy-mm-dd (maximum 90 jours)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public evolutionSolde(numeroClient: string, numeroCompte: string, dateDebut: string, dateFin: string, observe?: 'body', reportProgress?: boolean): Observable<EvolutionSolde>;
  public evolutionSolde(numeroClient: string, numeroCompte: string, dateDebut: string, dateFin: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EvolutionSolde>>;
  public evolutionSolde(numeroClient: string, numeroCompte: string, dateDebut: string, dateFin: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EvolutionSolde>>;
  public evolutionSolde(numeroClient: string, numeroCompte: string, dateDebut: string, dateFin: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling evolutionSolde.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling evolutionSolde.');
    }

    if (dateDebut === null || dateDebut === undefined) {
      throw new Error('Required parameter dateDebut was null or undefined when calling evolutionSolde.');
    }

    if (dateFin === null || dateFin === undefined) {
      throw new Error('Required parameter dateFin was null or undefined when calling evolutionSolde.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (dateDebut !== undefined && dateDebut !== null) {
      queryParameters = queryParameters.set('dateDebut', dateDebut as any);
    }
    if (dateFin !== undefined && dateFin !== null) {
      queryParameters = queryParameters.set('dateFin', dateFin as any);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<EvolutionSolde>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/evolutionSolde`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * R�cup�re les flux d�bit et cr�dit d&#x27;un mois donn�
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param dateDebut date de d�but des mouvements au format yyyy-mm-dd (maximum 90 jours)
   * @param dateFin date de fin des mouvements au format yyyy-mm-dd (maximum 90 jours)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public flux(numeroClient: string, numeroCompte: string, dateDebut: string, dateFin: string, observe?: 'body', reportProgress?: boolean): Observable<Flux>;
  public flux(numeroClient: string, numeroCompte: string, dateDebut: string, dateFin: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Flux>>;
  public flux(numeroClient: string, numeroCompte: string, dateDebut: string, dateFin: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Flux>>;
  public flux(numeroClient: string, numeroCompte: string, dateDebut: string, dateFin: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling flux.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling flux.');
    }

    if (dateDebut === null || dateDebut === undefined) {
      throw new Error('Required parameter dateDebut was null or undefined when calling flux.');
    }

    if (dateFin === null || dateFin === undefined) {
      throw new Error('Required parameter dateFin was null or undefined when calling flux.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (dateDebut !== undefined && dateDebut !== null) {
      queryParameters = queryParameters.set('dateDebut', dateDebut as any);
    }
    if (dateFin !== undefined && dateFin !== null) {
      queryParameters = queryParameters.set('dateFin', dateFin as any);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Flux>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/flux`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Affiche les soldes quotidiens du mois encours pour les comtpes courants, et le solde mensuel pour les �pargnes
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public graphe(numeroClient: string, numeroCompte: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
  public graphe(numeroClient: string, numeroCompte: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
  public graphe(numeroClient: string, numeroCompte: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
  public graphe(numeroClient: string, numeroCompte: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling graphe.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling graphe.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'image/png',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Blob>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/graphe`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Affiche la jauge d&#x27;un compte
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public jauge(numeroClient: string, numeroCompte: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
  public jauge(numeroClient: string, numeroCompte: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
  public jauge(numeroClient: string, numeroCompte: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
  public jauge(numeroClient: string, numeroCompte: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling jauge.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling jauge.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'image/png',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Blob>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/jauge`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Liste des comptes
   * @param numeroClient num�ro du client
   * @param typeListe
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listeComptes(numeroClient: string, typeListe: TypeListeCompteEnum, observe?: 'body', reportProgress?: boolean): Observable<CompteListe[]>;
  public listeComptes(numeroClient: string, typeListe: TypeListeCompteEnum, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompteListe[]>>;
  public listeComptes(numeroClient: string, typeListe: TypeListeCompteEnum, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompteListe[]>>;
  public listeComptes(numeroClient: string, typeListe: TypeListeCompteEnum, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling listeComptes.');
    }

    if (typeListe === null || typeListe === undefined) {
      throw new Error('Required parameter typeListe was null or undefined when calling listeComptes.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (typeListe !== undefined && typeListe !== null) {
      queryParameters = queryParameters.set('typeListe', typeListe as any);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<CompteListe[]>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * R�cup�re la liste des mouvements d&#x27;un compte sur une p�riode donn�e et pagin�
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param dateDebut date de d�but des mouvements au format yyyy-mm-dd (maximum 90 jours)
   * @param dateFin date de fin des mouvements au format yyyy-mm-dd (maximum 90 jours)
   * @param indiceDebut indice de d�part pour la pagination (0 par d�faut)
   * @param nbElements nombre d&#x27;�l�ments par page (100 par d�faut, max &#x3D; 1000)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public mouvements(numeroClient: string, numeroCompte: string, dateDebut: string, dateFin: string, indiceDebut?: number, nbElements?: number, observe?: 'body', reportProgress?: boolean): Observable<Mouvement[]>;
  public mouvements(numeroClient: string, numeroCompte: string, dateDebut: string, dateFin: string, indiceDebut?: number, nbElements?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Mouvement[]>>;
  public mouvements(numeroClient: string, numeroCompte: string, dateDebut: string, dateFin: string, indiceDebut?: number, nbElements?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Mouvement[]>>;
  public mouvements(numeroClient: string, numeroCompte: string, dateDebut: string, dateFin: string, indiceDebut?: number, nbElements?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling mouvements.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling mouvements.');
    }

    if (dateDebut === null || dateDebut === undefined) {
      throw new Error('Required parameter dateDebut was null or undefined when calling mouvements.');
    }

    if (dateFin === null || dateFin === undefined) {
      throw new Error('Required parameter dateFin was null or undefined when calling mouvements.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (dateDebut !== undefined && dateDebut !== null) {
      queryParameters = queryParameters.set('dateDebut', dateDebut as any);
    }
    if (dateFin !== undefined && dateFin !== null) {
      queryParameters = queryParameters.set('dateFin', dateFin as any);
    }
    if (indiceDebut !== undefined && indiceDebut !== null) {
      queryParameters = queryParameters.set('indiceDebut', indiceDebut as any);
    }
    if (nbElements !== undefined && nbElements !== null) {
      queryParameters = queryParameters.set('nbElements', nbElements as any);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Mouvement[]>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/mouvements`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * R�cup�re les op�rations d&#x27;un encours carte d&#x27;un compte
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param date date en yyyy-mm-dd
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public operationsCarteCompte(numeroClient: string, numeroCompte: string, date: string, observe?: 'body', reportProgress?: boolean): Observable<OperationsCarteCompte>;
  public operationsCarteCompte(numeroClient: string, numeroCompte: string, date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OperationsCarteCompte>>;
  public operationsCarteCompte(numeroClient: string, numeroCompte: string, date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OperationsCarteCompte>>;
  public operationsCarteCompte(numeroClient: string, numeroCompte: string, date: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling operationsCarteCompte.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling operationsCarteCompte.');
    }

    if (date === null || date === undefined) {
      throw new Error('Required parameter date was null or undefined when calling operationsCarteCompte.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<OperationsCarteCompte>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/encours/${encodeURIComponent(String(date))}/operations`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Recherche des mouvements
   * @param body La recherche des mouvements
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rechercheMouvements(body: RechercheMouvement, numeroClient: string, numeroCompte: string, observe?: 'body', reportProgress?: boolean): Observable<Mouvement[]>;
  public rechercheMouvements(body: RechercheMouvement, numeroClient: string, numeroCompte: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Mouvement[]>>;
  public rechercheMouvements(body: RechercheMouvement, numeroClient: string, numeroCompte: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Mouvement[]>>;
  public rechercheMouvements(body: RechercheMouvement, numeroClient: string, numeroCompte: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling rechercheMouvements.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling rechercheMouvements.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling rechercheMouvements.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<Mouvement[]>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/mouvements`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * R�partition des mouvement d&#x27;un compte (d�penses et recettes)
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param dateDebut date de d�but au format yyyy-mm-dd (maximum 90 jours)
   * @param dateFin date de fin au format yyyy-mm-dd (maximum 90 jours)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public repartitionCategorie(numeroClient: string, numeroCompte: string, dateDebut: string, dateFin: string, observe?: 'body', reportProgress?: boolean): Observable<Repartition>;
  public repartitionCategorie(numeroClient: string, numeroCompte: string, dateDebut: string, dateFin: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Repartition>>;
  public repartitionCategorie(numeroClient: string, numeroCompte: string, dateDebut: string, dateFin: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Repartition>>;
  public repartitionCategorie(numeroClient: string, numeroCompte: string, dateDebut: string, dateFin: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling repartitionCategorie.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling repartitionCategorie.');
    }

    if (dateDebut === null || dateDebut === undefined) {
      throw new Error('Required parameter dateDebut was null or undefined when calling repartitionCategorie.');
    }

    if (dateFin === null || dateFin === undefined) {
      throw new Error('Required parameter dateFin was null or undefined when calling repartitionCategorie.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (dateDebut !== undefined && dateDebut !== null) {
      queryParameters = queryParameters.set('dateDebut', dateDebut as any);
    }
    if (dateFin !== undefined && dateFin !== null) {
      queryParameters = queryParameters.set('dateFin', dateFin as any);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Repartition>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/repartition`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Historique des 3 derniers taux du compte
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public tauxCompte(numeroClient: string, numeroCompte: string, observe?: 'body', reportProgress?: boolean): Observable<TauxCompte[]>;
  public tauxCompte(numeroClient: string, numeroCompte: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TauxCompte[]>>;
  public tauxCompte(numeroClient: string, numeroCompte: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TauxCompte[]>>;
  public tauxCompte(numeroClient: string, numeroCompte: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling tauxCompte.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling tauxCompte.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<TauxCompte[]>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/taux`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Met � jour les pr�f�rences de la jauge pour un compte
   * @param body Les bornes � mettre � jour
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateJauge(body: BorneMinMax, numeroClient: string, numeroCompte: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public updateJauge(body: BorneMinMax, numeroClient: string, numeroCompte: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public updateJauge(body: BorneMinMax, numeroClient: string, numeroCompte: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public updateJauge(body: BorneMinMax, numeroClient: string, numeroCompte: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateJauge.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling updateJauge.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling updateJauge.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/jauge`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Liste des virements d&#x27;un compte
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param mobile true si on est sur mobile et que l&#x27;on ne veut pas les virements internationaux et les virements comptabilis�s (historique virements permanents)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public virements(numeroClient: string, numeroCompte: NumeroCompte, mobile?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<VirementListe>>;
  public virements(numeroClient: string, numeroCompte: NumeroCompte, mobile?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VirementListe>>>;
  public virements(numeroClient: string, numeroCompte: NumeroCompte, mobile?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VirementListe>>>;
  public virements(numeroClient: string, numeroCompte: NumeroCompte, mobile?: boolean, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling virements.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling virements.');
    }


    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (mobile !== undefined && mobile !== null) {
      queryParameters = queryParameters.set('mobile', <any>mobile);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<VirementListe>>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/virements`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Recherche des virements permanent
   * @param body La recherche des virements
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public virementsPermanent(body: RechercheVirement, numeroClient: string, numeroCompte: string, observe?: 'body', reportProgress?: boolean): Observable<VirementPermanentListe[]>;
  public virementsPermanent(body: RechercheVirement, numeroClient: string, numeroCompte: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VirementPermanentListe[]>>;
  public virementsPermanent(body: RechercheVirement, numeroClient: string, numeroCompte: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VirementPermanentListe[]>>;
  public virementsPermanent(body: RechercheVirement, numeroClient: string, numeroCompte: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling virementsPermanent.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling virementsPermanent.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling virementsPermanent.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<VirementPermanentListe[]>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/virementsPermanent`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Liste des virements � valider d&#x27;un compte
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public virementsAValider(numeroClient: string, numeroCompte: NumeroCompte, observe?: 'body', reportProgress?: boolean): Observable<VirementListe[]>;
  public virementsAValider(numeroClient: string, numeroCompte: NumeroCompte, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VirementListe[]>>;
  public virementsAValider(numeroClient: string, numeroCompte: NumeroCompte, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VirementListe[]>>;
  public virementsAValider(numeroClient: string, numeroCompte: NumeroCompte, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling virementsAValider.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling virementsAValider.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<VirementListe[]>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/virementsAValider`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Liste des virements permanent � valider d&#x27;un compte
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public virementsAValiderPermanent(numeroClient: string, numeroCompte: NumeroCompte, observe?: 'body', reportProgress?: boolean): Observable<VirementPermanentListe[]>;
  public virementsAValiderPermanent(numeroClient: string, numeroCompte: NumeroCompte, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VirementPermanentListe[]>>;
  public virementsAValiderPermanent(numeroClient: string, numeroCompte: NumeroCompte, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VirementPermanentListe[]>>;
  public virementsAValiderPermanent(numeroClient: string, numeroCompte: NumeroCompte, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling virementsAValiderPermanent.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling virementsAValiderPermanent.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<VirementPermanentListe[]>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/virementsAValiderPermanent`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Liste des virements permanents rejet�s d&#x27;un compte
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param international true si on souhaite remonter les virements internationaux
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public virementsPermanantsRejetes(numeroClient: string, numeroCompte: NumeroCompte, international?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<VirementPermanentListe>>;
  public virementsPermanantsRejetes(numeroClient: string, numeroCompte: NumeroCompte, international?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VirementPermanentListe>>>;
  public virementsPermanantsRejetes(numeroClient: string, numeroCompte: NumeroCompte, international?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VirementPermanentListe>>>;
  public virementsPermanantsRejetes(numeroClient: string, numeroCompte: NumeroCompte, international?: boolean, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling virementsPermanantsRejetes.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling virementsPermanantsRejetes.');
    }


    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (international !== undefined && international !== null) {
      queryParameters = queryParameters.set('international', <any>international);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<VirementPermanentListe>>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/virementsPermanentsRejetes`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Liste des virements rejet�s d&#x27;un compte
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param international true si on souhaite remonter les virements internationaux
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public virementsRejetes(numeroClient: string, numeroCompte: NumeroCompte, international?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<VirementListe>>;
  public virementsRejetes(numeroClient: string, numeroCompte: NumeroCompte, international?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VirementListe>>>;
  public virementsRejetes(numeroClient: string, numeroCompte: NumeroCompte, international?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VirementListe>>>;
  public virementsRejetes(numeroClient: string, numeroCompte: NumeroCompte, international?: boolean, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling virementsRejetes.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling virementsRejetes.');
    }


    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (international !== undefined && international !== null) {
      queryParameters = queryParameters.set('international', <any>international);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<VirementListe>>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/virementsRejetes`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
