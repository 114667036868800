import {Client} from '../../../api/model/client';
import {User} from '../../models/user';
import TypeAbonnementEnum = Client.TypeAbonnementEnum;

/**
 * Entr�e du menu.
 */
export class MenuItem {
  private readonly _libelle: string;
  private readonly _url?: string;
  private readonly _abonnementsAutorises: TypeAbonnementEnum[];
  /** Le sous menus est compos� d'un tableau de menus */
  private readonly _sousMenus?: MenuItem[];
  private readonly _droitsMenu?: DroitMenu[];
  private readonly _externalUrl: boolean;
  private readonly _action: () => void;

  /**
   * Constructeur entr�e.
   * @param libelle libell�
   * @param urlLien URL du menu
   * @param abonnementsAutorises les r�les pouvant y acc�der
   * @param sousMenus les sous-menus
   * @param droitsMenu les droits du menu
   * @param externalUrl l'url est externe
   * @param action l'action � r�aliser
   */
  constructor(libelle: string, abonnementsAutorises: TypeAbonnementEnum[], urlLien?: string, sousMenus?: MenuItem[],
              droitsMenu?: DroitMenu[], externalUrl: boolean = false, action?: () => void) {
    this._libelle = libelle;
    this._abonnementsAutorises = abonnementsAutorises;
    this._sousMenus = sousMenus;
    this._url = urlLien;
    this._droitsMenu = droitsMenu;
    this._externalUrl = externalUrl;
    this._action = action;
  }

  /** libell� du menu. */
  get libelle(): string {
    return this._libelle;
  }

  /** URL du lien. */
  get url(): string {
    return this._url;
  }

  get sousMenus(): MenuItem[] {
    return this._sousMenus;
  }

  get abonnementsAutorises(): Client.TypeAbonnementEnum[] {
    return this._abonnementsAutorises;
  }

  get droitsMenu(): DroitMenu[] {
    return this._droitsMenu;
  }

  get externalUrl(): boolean {
    return this._externalUrl;
  }

  get action(): () => void {
    return this._action;
  }

  static createItem(libelle: string, rolesAutorises: TypeAbonnementEnum[], urlLien: string, droitsMenu?: DroitMenu[], externalUrl: boolean = false, action?: () => void): MenuItem {
    return new MenuItem(libelle, rolesAutorises, urlLien, undefined, droitsMenu, externalUrl, action);
  }

  /** Sous menu avec titre (dans les op�rations par exemple). */
  static createSousItem(libelle: string, rolesAutorises: TypeAbonnementEnum[], sousMenus: MenuItem[],
                        droitsMenu?: DroitMenu[]): MenuItem {
    return new MenuItem(libelle, rolesAutorises, undefined, sousMenus, droitsMenu);
  }

  /** Sous-menu sans titre. */
  static createSousItemSansTitre(rolesAutorises: TypeAbonnementEnum[], sousMenus: MenuItem[], droitsMenu?: DroitMenu[]): MenuItem {
    return new MenuItem(null, rolesAutorises, undefined, sousMenus, droitsMenu);
  }

  /** A-t-il l'abonnement. */
  hasAbonnement(typeAbo: TypeAbonnementEnum): boolean {
    return this._abonnementsAutorises.includes(typeAbo);
  }

  /** A-t-il au moins 1 des droits? */
  hasDroit(user: User): boolean {
    if (this._droitsMenu && this._droitsMenu.length > 0) {
      for (const droit of this._droitsMenu) {
        let access = false;
        switch (droit) {
          case 'saisisseur':
            access = user.hasDroitSaisisseur();
            break;
          case 'valideur':
            access = user.hasDroitValideur();
            break;
          case 'complet':
            access = user.isComplet();
            break;
          case 'entrepriseIndividuelle':
            access = user?.client?.entrepriseIndividuelle;
            break;
          case 'clientPersonnePhysique':
            access = user?.client?.typeClient === Client.TypeClientEnum.Physique;
            break;
          case 'credit':
            access = user?.client?.eligibleCredit;
            break;
        }
        if (access) {
          return true;
        }
        // sinon on essaye le droit suivant
      }
      // il n'a aucun droit
      return false;
    }

    // aucun blocage sur ce menu
    return true;
  }
}

/** Droits pour les entr�es de menu. */
export type DroitMenu = 'saisisseur' | 'valideur' | 'complet' | 'entrepriseIndividuelle' | 'clientPersonnePhysique' | 'credit';
export const DroitMenu = {
  Saisisseur: 'saisisseur' as DroitMenu,
  Valideur: 'valideur' as DroitMenu,
  Complet: 'complet' as DroitMenu,
  EntrepriseIndividuelle: 'entrepriseIndividuelle' as DroitMenu,
  ClientPersonnePhysique: 'clientPersonnePhysique' as DroitMenu,
  Credit: 'credit' as DroitMenu,
};
