import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {RepartitionCategorie} from '../../../api/model/repartitionCategorie';
import {ComptesService} from '../../services/comptes.service';
import {GoogleChartRepartitionService} from '../../services/google-chart/google-chart-repartition.service';
import {IconesService} from '../../services/icones.service';

@Component({
  selector: 'app-graphe-repartition-compte',
  templateUrl: './graphe-repartition-compte.component.html',
  styleUrls: ['./graphe-repartition-compte.component.css'],
})
export class GrapheRepartitionCompteComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() grapheId: string;
  @Input() repartitions: RepartitionCategorie[];
  @Input() total: number;

  constructor(private compteService: ComptesService, private logger: NGXLogger,
              private googleChartRepartitionService: GoogleChartRepartitionService, private iconesService: IconesService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // il faut qu'il y ait des r�partitions
    if (this.repartitions && this.repartitions.length > 0) {
      const data = [];
      const color: string[] = [];

      for (const valeur of this.repartitions) {
        data.push([this.iconesService.categorieLibelle(valeur.categorie), Math.abs(valeur.montant), this.buildTootip(valeur)]);
        color.push(this.iconesService.categorieCodeCouleur(valeur.categorie));
      }
      this.googleChartRepartitionService.buildChart(this.grapheId, data, color);
    }
  }

  ngOnDestroy() {
    this.googleChartRepartitionService.destroyChart(this.grapheId);
  }

  private buildTootip(valeur: RepartitionCategorie): string {
    return '<div class=\'d-flex tooltip_chart\' style=\'background: '
      + this.iconesService.categorieCodeCouleur(valeur.categorie) + ';\'><div class=\'icon_chart\'><i class=\''
      + this.iconesService.categorieIcon(valeur.categorie) +
      '\'></i></div><div class=\'d-flex flex-column pl-2\'><div class=\'title_chart text-nowrap\'>' +
      this.iconesService.categorieLibelle(valeur.categorie)
      + '</div><div class=\'montant_chart text-nowrap\'>' + (valeur.montant * 100 / this.total).toFixed(1)
      + '%</div></div></div>';
  }

}
