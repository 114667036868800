<app-base-header *ngIf="!popup" [titre]="titre"></app-base-header>

<main *ngIf="!erreur else Erreur" class="main">

  <ng-content></ng-content>

  <section class="anr_form_section">
    <div [ngClass]="{'container': !popup}">
      <div class="row">
        <div class="col-lg-12 pb-4">
          <div [ngClass]="{'py-5': !popup}" class="anr_block">
            <div class="anr_title_form text-center pb-3">Validation de votre demande</div>
            <p class="text-center">Vous allez recevoir un email sur votre adresse �lectronique connu par la Banque
              SOCREDO
              avec un code. <br/> Veuillez patienter, cette op�ration peut prendre quelques minutes.</p>

            <form (validSubmit)="onSubmit()" [formGroup]="formGroup" class="anr_form" id="anr_form">
              <div class="reset_block text-center">
                <button (click)="toggleInfosAnr()" class="btn btn-outline-primary custom-popover" type="button">
                  je n'ai pas re�u d'email
                </button>
              </div>
              <div class="code_block pt-5 pb-3">
                <div class="code_content_block form-group p-1">
                  <div class="input_block d-flex align-items-center justify-content-center flex-grow-1">
                    <input class="form-control" formControlName="anr" id="validation_code" maxlength="6" name="validation_code"
                           type="text">
                  </div>
                </div>
                <div *ngIf="formGroup.controls.anr.touched && formGroup.controls.anr.hasError('pattern')"
                     class="invalid-feedback d-block text-center">
                  Le champ doit �tre compos� de 6 caract�res num�riques.
                </div>
              </div>
              <div class="actions_block text-center">
                <button (confirm)="retourLink()" [closeOnOutsideClick]="true"
                        [popoverMessage]="'Etes-vous s�r de vouloir abandonner votre saisie ?'"
                        [popoverTitle]="''"
                        cancelText="Annuler"
                        class="btn btn-outline-secondary"
                        confirmText="Confirmer"
                        mwlConfirmationPopover type="button">Annuler
                </button>
                <button [ladda]="isSubmit" class="btn btn-outline-primary" type="submit">Valider</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>


<section class="modal confirm-remove-modal"
         id="infos-anr" role="dialog" tabindex="-1">
  <div [style.max-width.px]="800" class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg"
       role="document">
    <div class="modal-content position-relative py-5 px-3">
      <div class="modal-header"><a (click)="toggleInfosAnr()" class="close-modal-btn"><i
        class="icon-close cursor-pointer"></i></a>
      </div>
      <div class="modal-body text-start px-3">
        <app-infos-anr [typeAnr]="demandeAnr.type"></app-infos-anr>
      </div>
    </div>
  </div>
</section>


<ng-template #Erreur>
  <app-message-erreur [erreur]="erreur"></app-message-erreur>
</ng-template>

