import {registerLocaleData} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgIdleModule} from '@ng-idle/core';
import {LaddaModule} from 'angular2-ladda';
import {BootstrapVersion, NgBootstrapFormValidationModule} from 'ng-bootstrap-form-validation';
import {ApiModule} from './api/api.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreServiceModule} from './core/core-service.module';
import {CoreModule} from './core/core.module';
import {CustomErrors} from './core/errors/custom-errors';
import {SentryErrorHandler} from './sentry-error-handler';

// on importe le fran�ais pour le formatage des date & co
// https://angular.io/guide/i18n
registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    // angular
    BrowserAnimationsModule,
    HttpClientModule,

    // Bootstrap
    // https://github.com/third774/ng-bootstrap-form-validation
    NgBootstrapFormValidationModule.forRoot({
      bootstrapVersion: BootstrapVersion.Four,
      customErrorMessages: CustomErrors.CUSTOM_ERRORS,
    }),
    // https://github.com/moff/angular2-ladda
    LaddaModule.forRoot({
      style: 'expand-left',
      spinnerColor: 'black',
    }),

    // https://www.npmjs.com/package/@ng-idle/core
    NgIdleModule.forRoot(),

    // core & co
    CoreModule,
    // https://angular.io/guide/singleton-services
    // il ne faut importer ce service qu'ici sinon les services ne sont pas des singletons!
    CoreServiceModule,
    ApiModule,

    // default app routing
    AppRoutingModule,

  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'fr'},
    {provide: ErrorHandler, useClass: SentryErrorHandler},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

}
