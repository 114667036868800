/**
 * Hoba/wesoc API 3.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';

import {NumeroCompte} from '../model/numeroCompte';
import {RechercheReleves} from '../model/rechercheReleves';
import {Releve} from '../model/releve';
import {Configuration} from '../conf/configuration';
import {BASE_PATH} from '../conf/variables';
import {CustomHttpUrlEncodingCodec} from '../conf/encoder';
import {ParametreReleves} from '../model/parametreReleves';
import {DemandeAnr} from '../model/demandeAnr';
import {ParametrePartage} from '../model/parametrePartage';


@Injectable()
export class RelevesService {

  protected basePath = 'https://www.socms.pf/hoba/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   *
   * Recherche des releves
   * @param body La recherche des releves
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rechercheReleves(body: RechercheReleves, numeroClient: string, numeroCompte: NumeroCompte, observe?: 'body', reportProgress?: boolean): Observable<Array<Releve>>;
  public rechercheReleves(body: RechercheReleves, numeroClient: string, numeroCompte: NumeroCompte, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Releve>>>;
  public rechercheReleves(body: RechercheReleves, numeroClient: string, numeroCompte: NumeroCompte, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Releve>>>;
  public rechercheReleves(body: RechercheReleves, numeroClient: string, numeroCompte: NumeroCompte, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling rechercheReleves.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling rechercheReleves.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling rechercheReleves.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<Array<Releve>>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/releves`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Retourne un ou plusieurs pdf de releve de compte
   * @param numeroClient num�ro du client
   * @param numeroCompte num�ro du compte
   * @param idReleves tableau des ids des releves � t�l�charger
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public telechargeReleves(numeroClient: string, numeroCompte: NumeroCompte, idReleves: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
  public telechargeReleves(numeroClient: string, numeroCompte: NumeroCompte, idReleves: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
  public telechargeReleves(numeroClient: string, numeroCompte: NumeroCompte, idReleves: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
  public telechargeReleves(numeroClient: string, numeroCompte: NumeroCompte, idReleves: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling telechargeReleves.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling telechargeReleves.');
    }

    if (idReleves === null || idReleves === undefined) {
      throw new Error('Required parameter idReleves was null or undefined when calling telechargeReleves.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (idReleves) {
      idReleves.forEach((element) => {
        queryParameters = queryParameters.append('idReleves', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/octet-stream'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', 'application/octet-stream, application/json; q=0.9');
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Blob>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/releves`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        responseType: 'blob' as 'json',
        reportProgress: reportProgress
      }
    );
  }


  /**
   *
   * Enregistre les pr�f�rences de releves
   * @param body Le parametre selectionn�
   * @param numeroClient num�ro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public majParametreReleves(body: string, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public majParametreReleves(body: string, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public majParametreReleves(body: string, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public majParametreReleves(body: string, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling majParametreReleves.');
    }
    // le body doit �tre entre guillemets
    body = '"' + body + '"';

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling majParametreReleves.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/releves`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Retourne les pr�f�rences de releves
   * @param numeroClient num�ro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public parametreReleves(numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<ParametreReleves>;
  public parametreReleves(numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParametreReleves>>;
  public parametreReleves(numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParametreReleves>>;
  public parametreReleves(numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling parametreReleves.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ParametreReleves>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/releves`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
   *
   * Supprime les pr�f�rences de partage des donn�es
   * @param numeroClient num�ro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteParametrePartage(numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteParametrePartage(numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteParametrePartage(numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteParametrePartage(numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling deleteParametrePartage.');
    }

    let headers = this.defaultHeaders;

    // authentication (BearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('delete', `${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/partages`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Enregistre les pr�f�rences de partage des donn�es
   * @param body Les pr�f�rences de partage
   * @param numeroClient num�ro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public majParametrePartage(body: ParametrePartage, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;
  public majParametrePartage(body: ParametrePartage, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;
  public majParametrePartage(body: ParametrePartage, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;
  public majParametrePartage(body: ParametrePartage, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling majParametrePartage.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling majParametrePartage.');
    }

    let headers = this.defaultHeaders;

    // authentication (BearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<DemandeAnr>('put', `${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/partages`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
   *
   * Retourne les parametres de partage
   * @param numeroClient num�ro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public partageDonnees(numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<ParametrePartage>;
  public partageDonnees(numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParametrePartage>>;
  public partageDonnees(numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParametrePartage>>;
  public partageDonnees(numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling partageDonnees.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ParametrePartage>('get', `${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/partages`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
