import {Component, OnDestroy, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Subscription} from 'rxjs';
import {Client} from '../../../api/model/client';
import {UserService} from '../../services/user.service';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-footer-contact',
  templateUrl: './footer-contact.component.html',
  styleUrls: ['./footer-contact.component.css'],
})
export class FooterContactComponent implements OnInit, OnDestroy {
  /** liste des pages avec tutoriel */
  private static PAGES_TUTORIEL: string[] = ['/home', '/comptes/detail', '/comptes/liste'];

  client: Client;
  /** Pour les observable. */
  private subscription = new Subscription();

  public urls = environment.urlsInstitutionelles;

  item: 'Agence' | 'Conseiller' | 'Nous appeler';


  constructor(private userService: UserService, private logger: NGXLogger, private router: Router) {
  }

  ngOnInit() {
    this.client = this.userService.clientActif();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Clique une zone du footer (pour mobile)
   * @param item item s�lectionn�
   */
  selectItem(item: 'Agence' | 'Conseiller' | 'Nous appeler') {
    this.item = item;
  }

  /**
   * D�selecte une zone du footer
   */
  unselectItem() {
    this.item = null;
  }

  hasTutoriel() {

    if (!this.userService.user?.isDemo) {

      return FooterContactComponent.PAGES_TUTORIEL.find((value) => {
        return value === this.router.url;
      });
    }
    return false;
  }

}
