/**
 * Hoba/wesoc API 3.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';

import {Budget} from '../model/budget';
import {BudgetCategorie} from '../model/budgetCategorie';
import {Mouvement} from '../model/mouvement';
import {NewBudget} from '../model/newBudget';
import {Configuration} from '../conf/configuration';
import {BASE_PATH} from '../conf/variables';
import {CustomHttpUrlEncodingCodec} from '../conf/encoder';


@Injectable()
export class BudgetService {

  protected basePath = 'https://www.socms.pf/hoba/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   *
   * Ajoute un budget
   * @param body Le budget � cr�er
   * @param numeroClient num�ro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ajouteBudget(body: NewBudget, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public ajouteBudget(body: NewBudget, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public ajouteBudget(body: NewBudget, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public ajouteBudget(body: NewBudget, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling ajouteBudget.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling ajouteBudget.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/budgets`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * D�tail d&#x27;un budget
   * @param numeroClient num�ro du client
   * @param idBudget id du budget
   * @param dateDebut la date de d�but
   * @param dateFin la date de fin
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public detailBudget(numeroClient: string, idBudget: number, dateDebut?: string, dateFin?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<BudgetCategorie>>;
  public detailBudget(numeroClient: string, idBudget: number, dateDebut?: string, dateFin?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BudgetCategorie>>>;
  public detailBudget(numeroClient: string, idBudget: number, dateDebut?: string, dateFin?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BudgetCategorie>>>;
  public detailBudget(numeroClient: string, idBudget: number, dateDebut?: string, dateFin?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling detailBudget.');
    }

    if (idBudget === null || idBudget === undefined) {
      throw new Error('Required parameter idBudget was null or undefined when calling detailBudget.');
    }


    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (dateDebut !== undefined && dateDebut !== null) {
      queryParameters = queryParameters.set('dateDebut', <any>dateDebut);
    }
    if (dateFin !== undefined && dateFin !== null) {
      queryParameters = queryParameters.set('dateFin', <any>dateFin);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<BudgetCategorie>>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/budgets/${encodeURIComponent(String(idBudget))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * R�cup�re la liste des budgets pour un compte
   * @param numeroClient num�ro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listeBudgets(numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Budget>>;
  public listeBudgets(numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Budget>>>;
  public listeBudgets(numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Budget>>>;
  public listeBudgets(numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling listeBudgets.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<Budget>>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/budgets`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Modifie un budget
   * @param body Le budget � modifier
   * @param numeroClient num�ro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public modifieBudget(body: Budget, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public modifieBudget(body: Budget, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public modifieBudget(body: Budget, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public modifieBudget(body: Budget, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling modifieBudget.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling modifieBudget.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/budgets`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * R�cup�re la liste des mouvements d&#x27;un budget pour une cat�gorie
   * @param numeroClient num�ro du client
   * @param idBudget id du budget
   * @param codeCategorie le code de la cat�gorie
   * @param dateDebut la date de d�but
   * @param dateFin la date de fin
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public mouvementsBudget(numeroClient: string, idBudget: number, codeCategorie: string, dateDebut?: string, dateFin?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Mouvement>>;
  public mouvementsBudget(numeroClient: string, idBudget: number, codeCategorie: string, dateDebut?: string, dateFin?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Mouvement>>>;
  public mouvementsBudget(numeroClient: string, idBudget: number, codeCategorie: string, dateDebut?: string, dateFin?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Mouvement>>>;
  public mouvementsBudget(numeroClient: string, idBudget: number, codeCategorie: string, dateDebut?: string, dateFin?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling mouvementsBudget.');
    }

    if (idBudget === null || idBudget === undefined) {
      throw new Error('Required parameter idBudget was null or undefined when calling mouvementsBudget.');
    }

    if (codeCategorie === null || codeCategorie === undefined) {
      throw new Error('Required parameter codeCategorie was null or undefined when calling mouvementsBudget.');
    }


    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (codeCategorie !== undefined && codeCategorie !== null) {
      queryParameters = queryParameters.set('codeCategorie', <any>codeCategorie);
    }
    if (dateDebut !== undefined && dateDebut !== null) {
      queryParameters = queryParameters.set('dateDebut', <any>dateDebut);
    }
    if (dateFin !== undefined && dateFin !== null) {
      queryParameters = queryParameters.set('dateFin', <any>dateFin);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<Mouvement>>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/budgets/${encodeURIComponent(String(idBudget))}/mouvements`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Supprimer un budget d&#x27;un compte
   * @param numeroClient num�ro du client
   * @param id l&#x27;id du budget � supprimer
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public supprimeBudget(numeroClient: string, id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public supprimeBudget(numeroClient: string, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public supprimeBudget(numeroClient: string, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public supprimeBudget(numeroClient: string, id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling supprimeBudget.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling supprimeBudget.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', <any>id);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/budgets`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
