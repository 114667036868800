/**
 * Hoba/wesoc API 3.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Droits d'une d�l�gation sur un compte
 */
export type DroitEnum = 'Consultation' | 'Valideur' | 'Saisisseur' | 'Saisisseur-Valideur' ;

export const DroitEnum = {
  Consultation: 'Consultation' as DroitEnum,
  Valideur: 'Valideur' as DroitEnum,
  Saisisseur: 'Saisisseur' as DroitEnum,
  SaisisseurValideur: 'Saisisseur-Valideur' as DroitEnum,
};
